import React from "react";
import "../../css/gallery_cards.css";
import "../../css/common.css"
import line from '../../css/line.png';
import GalleryItem2 from "./GalleryItem2";

const GalleryList2 = ({speed, galleryImgTitle, conceptStyle, fontSize, weddingData, initCount, photoWidth, photoHeight, gap, isBold, baseFontColor}) => {
  return (
      <div className='gallery_container'>
          <h3 className='spring_h3_title' style={{paddingTop: "76px", color: conceptStyle.titleColor, fontFamily: weddingData.fontFamilyEn}}>GALLERY</h3>
          <p className='p1_score_14' style={{fontFamily: conceptStyle.fontFamilyKo, fontSize: fontSize?fontSize:"14px", color: baseFontColor ? baseFontColor:"#313131", fontWeight : isBold ? 600 : 300}}> 사진을 클릭하시면 넘기며 보실 수 있습니다.</p>
          <GalleryItem2 speed={speed} galleryImgTitle={galleryImgTitle} weddingData={weddingData} initCount={initCount} photoWidth={photoWidth} photoHeight={photoHeight} gap={gap}/>
          <img className='line_img' src={line} alt='Line Image'></img>
      </div>
  );
};


export default GalleryList2;
