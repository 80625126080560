import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import babyStepMusicFile from "../asset/music/babyStep.mp3";
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const AnMinJu = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "[비상!!]세마(승수)와 민주가 결혼합니다!!!";
    const openDesc = "분당 라온제나, 오후 12시 30분 여러분을 초대합니다.";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/38/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // spring - 블루C
        concept: "SPRING",
        titleColor: "#505d89",
        pointColor: "#505d89",
        btnBgColor: "#8190be",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#f3f6ff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "방세마",
            bank: "새마을금고",
            accountNumber : "9003-2402-7877-8",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "방신혁",
            bank: "국민은행",
            accountNumber : "229-21-1114-347",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "최은희",
            bank: "농협은행",
            accountNumber : "346-0584-9682-13",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "안민주",
            bank: "기업은행",
            accountNumber : "519-068915-01-012",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "안세정",
            bank: "SC제일은행",
            accountNumber : "363-20-023556",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "서경미",
            bank: "하나은행",
            accountNumber : "010-2266-6937",
            isKakao : false
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "안민주",
            call: "010-7545-6937"
        },
        {
            role: "신부 부",
            name: "안세정",
            call: "010-3916-6937"
        },
        {
            role: "신부 모",
            name: "서경미",
            call: "010-2266-6937"
        }
    ]


    const groomCallInfoList = [
        {
            role: "신랑",
            name: "방세마",
            call: "010-2443-6937"
        },
        {
            role: "신랑 부",
            name: "방신혁",
            call: "010-5095-0666"
        },
        {
            role: "신랑 모",
            name: "최은희",
            call: "010-3327-7327"
        }
    ]

    const msg1 = "따뜻한 봄 햇살 4월에 저희 두사람을 이어줬듯, \n저희 두사람이 4월 20일에 결실을 맺으려 합니다. ";
    const msg2 = "햇살같은 봄날의 시작으로\n 앞으로 서로를 보듬어주고 온기있는 삶으로 \n평생 사랑하고 존중해주며 살아가겠습니다.";
    const msg3 = "저희가 앞으로 평생 써 나갈 \n소중한 이야기의 맨 앞장을 \n함께 축복해주시면 감사하겠습니다."

    const lng = 127.1298621;
    const lat = 37.4116464;

    const placeTelNum = "031-705-1133";

    const forBus = "야탑역 하차 \n\n 5, 15-1, 17, 17-1, 51, 55, 57, 77-1, 116-3,200\n222, 950. 52, 55-1, 60, 105, 119, 220, 250, 252\n300, 340, 340-1, 330, 350, 380, 1005, 3330\n4000, 8109, 9300, 9408, 9403, 9607, 5100, 5300"
    const forSubway = "분당성 야탑역 1번, 2번 출구"
    const forCar = "- 네비게이션: 경기도 성남시 분당구 성남대로 916번길 5, 8층 (야탑통366-1) \n 판교역 5분, 판교IC 7분 \n\n - 주차장: 라온제나분당(관보빌딩) 주차장(지하 2,3,4층)\n - 외부주차장: 야탑역 환승 주차장/라온 제나 분당 건물 뒷면 공영주차장";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "스릴 넘쳤지~!",
            brideAnswer: "긴장되었어~"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "많이 긴장돼~!",
            brideAnswer: "너무 설레~!"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "많이 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생 옆에서 사랑할게",
            brideAnswer: "평생 사랑할게"
        },
        {
            question : "마지막으로 하객분들께 전하고 싶은 이야기는?",
            groomAnswer: "열심히 사랑하자",
            brideAnswer: "사랑하며 살자"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();

        // 핀치 줌 방지 이벤트 등록
        const preventPinchZoom = (e) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventPinchZoom);
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505d89"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={babyStepMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"We're getting married"} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"} gap={"4px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default AnMinJu;
