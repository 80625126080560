import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';

const BaekJiWon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김태완🖤백지원 결혼합니다. ";
    const openDesc = "4월19일 오전10시50분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/58/thumb.JPG";
    
    const lng = 126.828818362901;
    const lat = 37.308201295112;

    const placeTelNum = "031-501-5900";

    const forParking = "- 제1주차장\nAW컨벤션 지상, 지하 1층, 2층\n\n- 제2주차장\n양지주차타워 (AW컨벤션주차타워)\n\n-제3주차장\nAW컨벤션 정문 맞은편 공영주차장\n\n- 제4주차장\nMK주차타워"
    const forBus = "- 안산 문화숲의 광장 하차 88, \n- 동남레이크빌 하차 99-1, 3100 \n - 대림호수공원아파트 하차 77, 98, 3";
    const forSubway = "4호선 고잔역 2번 출구\n셔틀버스는 예식 2시간 후까지 운행합니다.\n(셔틀버스 5분~7분 간격 수시운행 / 도보 15~20분)";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "김태완",
            bank: "신한은행",
            accountNumber : "110-451-570760",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8lrCBuv"
        },
        {
            role: "신랑 부",
            name: "김경춘",
            bank: "국민은행",
            accountNumber : "620601-01-072182",
            isKakao : false,
        },
        {
            role: "신랑 모",
            name: "오영미",
            bank: "신한은행",
            accountNumber : "110-357-153522",
            isKakao : false,
        }

    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "백지원",
            bank: "카카오뱅크",
            accountNumber : "3333-06-1669114",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FVy0cMShC"
        },
        {
            role: "신부 부",
            name: "백남관",
            bank: "국민은행",
            accountNumber : "350601-04-290952",
            isKakao : false,
        },
        {
            role: "신부 모",
            name: "김혜정",
            bank: "우리은행",
            accountNumber : "320-08-021562",
            isKakao : false,
        }
    ]

    const msg1 = "중학교 학창시절 친구로 시작된 인연이 \n오늘날 부부의 연이 되었습니다.";
    const msg2 = "사랑이란 이름으로 서로가 하나되는 \n그 어여쁜 하루에 감사한 분들을 모시고자 합니다.";
    const msg3 = "뜻깊은 자리에 함께 축복해주시면 \n더 없는 기쁨으로 간직하겠습니다.";
    const msg4 = "함께하며 닮은 미소를 가진 저희가 \n봄꽃처럼 반가이 기다리고 있겠습니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    // const brideCallInfoList = [
    //     {
    //         role: "신부",
    //         name: "김민지",
    //         call: "010-3268-9129"
    //     },
    //     {
    //         role: "신부 부",
    //         name: "김동수",
    //         call: "010-8831-9129"
    //     }
    // ]

    // const groomCallInfoList = [
    //     {
    //         role: "신랑",
    //         name: "윤만선",
    //         call: "010-7438-7417"
    //     },
    //     {
    //         role: "신랑 부",
    //         name: "윤세공",
    //         call: "010-2563-7417"
    //     },
    //     {
    //         role: "신랑 모",
    //         name: "최미아",
    //         call: "010-8972-7417"
    //     }
    // ]

    const interviewContent = [
        {
            question : "친구에서 연인이 된 후 \n우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "예쁘다많이",
            brideAnswer: "키많이컸네"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무설렌다",
            brideAnswer: "많이행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "내가더많이"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "내가잘할게",
            brideAnswer: "나도잘할게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "행복할게요",
            brideAnswer: "감사합니다"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        // document.body.style.overflow = 'hidden';
    
        // const timer = setTimeout(() => {
        //     document.body.style.overflow = '';
        // }, 3000);
    
        // return () => {
        //     clearTimeout(timer);
        //     document.body.style.overflow = '';
        // };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#FDE9CE"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#FDE9CE"} dateColor={"#FDE9CE"} top={"1%"} bottom={"25%"} bottom2={"17%"} dateBottom={"4%"} />
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default BaekJiWon;