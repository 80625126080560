import React, { useState, useEffect } from 'react';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Fall from '../component/Fall/Main_Fall';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import Info_Fall from '../component/Fall/Info_Fall';
import axios from 'axios';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import AccountList from '../component/Account/AccountList';
import GalleryList from '../component/Gallery/GalleryList';

const ParkSeoYoung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "FALL",
        titleColor: "#000000",
        pointColor: "#415100",
        btnBgColor: "#415100",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }
    
    const groomAccountList = [
        {
            role: "신랑",
            name: "황인욱",
            bank: "전북은행",
            accountNumber : "1021-01-4676727",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "진은영",
            bank: "우체국",
            accountNumber : "401455-02-019900",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박서영",
            bank: "국민은행",
            accountNumber : "506501-04-695541",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "박용주",
            bank: "농협은행",
            accountNumber : "352-1177-0342-23",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "강명기",
            bank: "국민은행",
            accountNumber : "763201-01-149307",
            isKakao : false
        }
    ]

    const msg1 = "웃는 모습이 너무나 예쁜 그녀를 만났습니다.\n배려하는 마음이 따뜻한 그를 만났습니다.";
    const msg2 = "우리는,\n서로에게 행복을 주는 사람을 만났습니다.\n운명처럼 만나게 된 우리의 인연,";
    const msg3 = "그 인연에 이끌려 이제 영원을\n함께 약속하려 합니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const openTitle = "황인욱 ♥ 박서영 결혼합니다.";
    const openDesc = "2025년 3월 1일 오후 12시 10분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/11/thumb.jpeg";

    const lng = 127.095705650207;
    const lat = 35.8598315367387;

    const placeTelNum = "063-246-5555";

    const forCar = "- 전주 IC>서부우회도로 : 10분\n- 전주역>백제대로 : 15분\n- 고속/시외버스터미널> 가리내로 : 12분";
    const forBus = "- 휴비스전주공장 하차 : 도보 5분\n(일반버스) 101, 103, 337, 383, 385, 386, 401,\n402, 420 \n(간선버스) 1001, 1002";
    const forParking = "더메이호텔 주차장 (3시간 무료)";

    const interviewContent = [
        {
            question : "서로의 첫인상을 다섯글자로 말한다면?",
            groomAnswer: "꿀피부여신",
            brideAnswer: "보조개미남"
        },
        {
            question : "어떤 모습이 가장 사랑스러워?",
            groomAnswer: "방긋웃을때",
            brideAnswer: "애교부릴때"
        },
        {
            question : "지금 이 순간 서로에게 하고 싶은 말은?",
            groomAnswer: "적당히예뻐",
            brideAnswer: "항상고마워"
        },
        {
            question : "함께 이루고 싶은 꿈이나 목표가 있어?",
            groomAnswer: "아들둘딸둘",
            brideAnswer: "행복한가정"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "에헤라디야",
            brideAnswer: "잘살아보세"
        }
    ]

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            console.log("response: ", response);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // Park Seo Young -> Park Seo young
    const toLowerCaseString = (str) => {
        const parts = str.split(" ");
        if (parts.length < 2) return str; // 성과 이름이 없으면 그대로 반환

        // 첫 단어(성)는 그대로 유지
        const firstPart = parts[0];

        // 두 번째 단어 이후로 처리
        const restParts = parts.slice(1).map((part, index) =>
            index === 0
                ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() // 첫 단어만 첫 글자 대문자
                : part.toLowerCase() // 나머지는 소문자로 변환
        );

        return `${firstPart} ${restParts.join(" ")}`;
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#415100"} loading={loading} />
            ) : (
                <> 
            <Music concept={conceptStyle.concept} initial={true}/>
            <Main_Fall photoWidth={"340px"} brideNameEn={toLowerCaseString(weddingData.brideNameEn)} groomNameEn={toLowerCaseString(weddingData.groomNameEn)} weddingData={weddingData} conceptStyle={conceptStyle}/>
            <Save_Date_Fall weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Info_Fall weddingData={weddingData}/>
            <Letter title={"우리 결혼합니다"}  msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} titleFontSize={"19px"} fontSize={"15px"}/>
            <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
            <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} fontFamilyEn={conceptStyle.fontFamilyEn} fontFamilyKo={conceptStyle.fontFamilyKo} fontSize={"15px"} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} titleFontSize={"15px"} fontSize={"14px"}/>
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate = {weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor} titleFontSize={"16px"} fontSize={"15px"}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring titleFontSize={"15px"} fontSize={"14px"} interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                                <Location_Spring  titleFontSize={"15px"} fontSize={"13px"} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc titleFontSize={"15px"} fontSize={"14px"} forBus={forBus} forParking={forParking} forCar={forCar} conceptStyle={conceptStyle}/>
                                <Sharing title={openTitle} description={openDesc} thumbImg={openThumb} conceptStyle={conceptStyle}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default ParkSeoYoung;