import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';

const GilTaeEun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "유근♥태은의 결혼식에 초대합니다";
    const openDesc = "25년 03월01일 토요일 오후 1시10분\n안산AW컨벤션";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/15/thumb.jpeg";

    const galleryImgTitle = [
        "웨딩촬영 어색하고 부끄러워"
        , "웃음기 빼고 갈게요~찰칵!"
        , "은이가 좋아하는 노랑색 꽃을 들고"
        , "검은색 드레스가 참 맘에 들어"
        , "은이가 좋아하는 사진"
        , "근이가 좋아하는 사진"
        , "인터넷 강의 강사 느낌~보험왕 느낌~잘생겼다♥"
        , "사진 찍고 근이가 베일 그대로 놓고 가버렸다^^"
        , "역시 우린 HIP해 썬구리 너무 잘 챙겼어 힛"
        , "촬영 작가님이 천천히 올라오세요~ 찰칵찰칵"
        , "껌 씹고 풍선 불기 뿌우우우 • ɞ•"
        , "풍선이 계속 날라가 잡으러 다니느라 힘들긴 했어"
        , "이때가 시작이였네..광대가 아프기 시작했던게"
        , "길 가다 우연치 않게 주황이 꽃 발견"
        , "여기에서 찍은 건 시골 온 느낌이야 따뜻해"
        , "카메라를 어딜 봐야 할까요"
        , "너무 행복하다♥"
        , "분홍색 예쁜 부케 완전 귀여운 우리"
        , "유근이 눈웃음 절대 지켜"
        , "근이가 부케 들어~"
        , "사람들이 너무 많아서 솔직히 조금 부끄러웠어"
        , "골목길에서 찰칵 빼꼼 내밀기"
        , "초등학생 때 이후로 양 갈래 땋은 머리 처음 깜찍"
        , "은이의 소원 성취! 같이 멜빵 입기"
        , "제목: 남의 집 대문 앞"
        , "백빈 건널목"
        , "여기가 나의 아저씨 촬영지였다며?"
        , "헤드락 아니야..나도 어깨동무하고 싶었을 뿐이야"
        , "꽃받침~“우린 귀여워” 주문 외우기"
        , "빙빙~돌아가는~트위스트"
        , "뽀뽀~만! 몇번짼지ㅎㅎㅎ"
        , "뭐가 그리 웃긴지~ 우리 행복하자♥"
    ]
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // spring - 오렌지
        concept: "SPRING",
        titleColor: "#FFAB35",
        pointColor: "#FFAB35",
        btnBgColor: "#ffcb8d",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5eb",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "윤유근",
            bank: "신한은행",
            accountNumber : "110 456 217951",
            isKakao : false,
        },
        {
            role: "신랑 부",
            name: "윤한웅",
            bank: "기업은행",
            accountNumber : "230 008352 02 026",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김민주",
            bank: "기업은행",
            accountNumber : "010 2316 6934",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "길태은",
            bank: "신한은행",
            accountNumber : "110 519 501458",
            isKakao : false,
        },
        {
            role: "신부 부",
            name: "길철영",
            bank: "카카오뱅크",
            accountNumber : "3333 31 1724296",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "이영희",
            bank: "카카오뱅크",
            accountNumber : "3333 07 0818060",
            isKakao : false
        }
    ]
    
    const msg1 = "두 사람이 꽃과 나무처럼 걸어와서\n서로의 모든 것이 되기 위해\n오랜 기다림 끝에 혼례식을 치르는 날\n세상은 더욱 아름다워라";
    const msg2 = "- 이해인, 사랑의 사람들이여 -";
    const msg3 = "\n유근이 태은이가 7년연애끝에 결혼합니다!!\n오래도록 저희를 지켜봐 주신 덕분에 \n저희가 드디어 결혼하게 되었습니다! \n저희의 첫출발을 함께 축복하고 기뻐해주시면 감사하겠습니다♥"

    const lng = 126.828818362901;
    const lat = 37.308201295112;

    const placeTelNum = "031-501-5900";

    const forParking = "[제 1주차장]\n- AW컨벤션 지상, 지하 1층, 2층\n[제 2주차장]\n- 양지주차타워 (AW컨벤션주차타워)\n[제 3주차장]\n- AW컨벤션 정문 맞은편 공영주차장\n[제 4주차장]\n- MK주차타워";
    const forBus = "[대림호수공원아파트 하차] 77, 98, 99-1\n[동남레이크빌 하차] 77, 98, 99-1, 314, 3100, 3101"
    const forSubway = "4호선 고잔역 2번 출구 앞 셔틀버스 운행\n(셔틀버스 5분~7분 간격 수시운행 / 도보 15~20분)"
    
    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "심장 터진날",
            brideAnswer: "참 귀여웠지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨린다",
            brideAnswer: "난 콩닥콩닥"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상 사랑해",
            brideAnswer: "마찬가지야"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "꼭 성공할게",
            brideAnswer: "부탁좀할게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "그때봐요♥"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FFAB35"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"유근이♥태은이 결혼해요"} msg1={msg1} msg2={msg2} msg3={msg3}conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <Gallery_Spring galleryImgTitle={galleryImgTitle} color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring weddingData={weddingData} conceptStyle={conceptStyle} interviewContent={interviewContent}/>
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default GilTaeEun;
