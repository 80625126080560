import React, { useEffect,  } from 'react';
import { useParams } from 'react-router-dom';

const Sharing = ({ conceptStyle, title, description, thumbImg, isBold}) => {
    const { mainPage } = useParams(); 
    useEffect(() => {
        // Kakao 객체 초기화 여부 확인
        if (!window.Kakao.isInitialized()) {
        window.Kakao.init('YOUR_APP_KEY'); 
        }
    }, []);

  // 카카오톡 공유 메시지 함수
  // https://developers.kakao.com/docs/latest/ko/message/js-link
    const shareMessage = () => {
        window.Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
            title: title,
            description: description,
            imageUrl: thumbImg,
            link: {
                mobileWebUrl: `https://moodsean.com/${mainPage}`,
                webUrl: `https://moodsean.com/${mainPage}`,
            },
            },
            buttons: [
            {
                title: '모바일 청첩장 보러가기',
                link: {
                    mobileWebUrl: `https://moodsean.com/${mainPage}`,
                    webUrl: `https://moodsean.com/${mainPage}`,
                },
            }
            ],
        });
        };

  return (
    <div style={{ width: '100vw', textAlign: 'center', paddingTop: "2rem", fontFamily: conceptStyle.fontFamilyKo, zIndex: "2000" }}>
      <button
        className="spring_btn"
        style={{ backgroundColor: conceptStyle.btnBgColor, color: conceptStyle.btnFontColor, fontFamily: conceptStyle.fontFamilyKo, fontWeight: isBold?600:300 }}
        onClick={shareMessage}
      >
        카카오톡 공유하기
      </button>
    </div>
  );
};

export default Sharing;
