import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';

import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Main_Spring from '../component/Spring/Main_Spring';

const KimSeolHwa = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김호근♥김설화 결혼합니다.";
    const openDesc = "25년 3월 2일 일요일 오후 12시40분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/61/thumb.jpg";
    
    const lng = 126.897273034482;
    const lat = 37.5682884450841;

    const placeTelNum = "02-3152-7700";

    const forParking = "월드컵경기장 서문 진입 후 서측 1,2 주차장 이용\n주차 접수대 사전 등록 후 출차 (90분 무료)\n\n경기·행사일 서측 주차장 만차 시 주차 요원 안내에 따라\n난지천공원, 평화의공원 이용 (3시간 무료)";
    const forBus = "월드컵경기장 서측, 문화비축기지 정류장\n    간선 - 571, 710, 760\n지선 - 7019. 7715, 8777\n광역 - 9711";
    const forSubway = "6호선 월드컵경기장역 2번 출구 도보 3분 (200m)\n월드컵경기장역 2번 출구에 경기장 서측(W) 방향 \n안내 배너\n* 환승역 : 2호선 합정역, 3호선 불광역·약수역, \n4호선 삼각지역, 5호선 공덕역";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        baseFontColor: "#000000",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김호근",
            bank: "우리은행",
            accountNumber : "1002-955-748236",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FL60rOg9D"
        },
        {
            role: "신랑 부",
            name: "김수",
            bank: "SC제일은행",
            accountNumber : "100-20-539215",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FVNEaTLma"
        },
        {
            role: "신랑 모",
            name: "우미경",
            bank: "국민은행",
            accountNumber : "430525-95-118284",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FEiimbypz"
        }

    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김설화",
            bank: "카카오뱅크",
            accountNumber : "3333-03-3337630",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7qsIFhP"
        },
        {
            role: "신부 부",
            name: "김경동",
            bank: "국민은행",
            accountNumber : "623501-01-443479",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FZi4Kot8p"
        },
        {
            role: "신부 모",
            name: "황정숙",
            bank: "경남은행",
            accountNumber : "522-21-0358316",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FFqgCWcOM"
        }
    ]

    const msg1 = "함께 있을 때 가장 나다운 모습이 되고,\n함께 있을 때 미래를 꿈꾸게 하는 사람을 만나\n평생을 함께하고자 합니다.";
    const msg2 = "지금처럼 서로의 가장 친한 친구가 되어\n예쁘고 행복하게 잘 살겠습니다.";
    const msg3 = "귀한 걸음으로 그 시작을 함께 축복해주세요.";

    const account_explain = "참석이 어려워 직접 축하를 전하지 못하는 분들을 위해 \n계좌번호를 기재하였습니다. 넓은 마음으로 양해 부탁드립니다. \n\n전해주시는 진심은 소중하게 간직하여 \n좋은 부부의 모습으로 보답하겠습니다."

    const parents = [
        {groom : "김수 . 우미경의 아들"},
        {bride : "김경동 . 황정숙의 딸"}
    ]
    // const brideCallInfoList = [
    //     {
    //         role: "신부",
    //         name: "김민지",
    //         call: "010-3268-9129"
    //     },
    //     {
    //         role: "신부 부",
    //         name: "김동수",
    //         call: "010-8831-9129"
    //     }
    // ]

    // const groomCallInfoList = [
    //     {
    //         role: "신랑",
    //         name: "윤만선",
    //         call: "010-7438-7417"
    //     },
    //     {
    //         role: "신랑 부",
    //         name: "윤세공",
    //         call: "010-2563-7417"
    //     },
    //     {
    //         role: "신랑 모",
    //         name: "최미아",
    //         call: "010-8972-7417"
    //     }
    // ]

    // const interviewContent = [
    //     {
    //         question : "친구에서 연인이 된 후 \n우리의 설레였던 첫 만남 기억해?",
    //         groomAnswer: "예쁘다많이",
    //         brideAnswer: "키많이컸네"
    //     },
    //     {
    //         question : "결혼을 앞둔 소감이 어때?",
    //         groomAnswer: "너무설렌다",
    //         brideAnswer: "많이행복해"
    //     },
    //     {
    //         question : "서로에게 하고 싶은 말은?",
    //         groomAnswer: "많이 사랑해",
    //         brideAnswer: "내가더많이"
    //     },
    //     {
    //         question : "유부남/녀 기념 각오 한마디!",
    //         groomAnswer: "내가잘할게",
    //         brideAnswer: "나도잘할게"
    //     },
    //     {
    //         question : "마지막으로 전하고 싶은 이야기는?",
    //         groomAnswer: "행복할게요",
    //         brideAnswer: "감사합니다"
    //     }
    // ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        // document.body.style.overflow = 'hidden';
    
        // const timer = setTimeout(() => {
        //     document.body.style.overflow = '';
        // }, 3000);
    
        // return () => {
        //     clearTimeout(timer);
        //     document.body.style.overflow = '';
        // };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };


        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring fontWeight={600} groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring parents={parents} isBold={true} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter isBold={true} title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <Dday_Spring isAttendFromClose={true} isBold={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList isBold={true} baseFontColor={conceptStyle.baseFontColor} fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList isBold={true} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    isBold={true}
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Location_Spring isBold={true} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc isBold={true} forParking={forParking} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KimSeolHwa;