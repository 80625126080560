import React, { useState, useEffect, useRef } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Sharing from '../component/Sharing';
import Music from '../component/Music';
import GalleryList from '../component/Gallery/GalleryList';

const JeongYunJi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:'',
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "파란 수국이 만발하던 계절에 만나\n하얀 목련이 향을 내뿜는 계절에 평생을 약속합니다.";
    const msg2 = "꽃이 피고 지듯 \n인생에도 행복한 순간과 힘든 나날들이 찾아올 테지만, \n사랑하고 아끼는 마음을 간직하며 함께 살아가겠습니다.";
    const msg3 = "그런 저희의 앞날에 축복의 걸음 하시어 \n격려하여 주신다면 더없이 감사하겠습니다.";

    const lng = 126.9683413;
    const lat = 37.5606355;

    const placeTelNum = "02-313-2480";

    const forCar = "네비게이션 * 별관주차장을 이용하시면 더욱 여유롭게 주차장을 이용하실 수 있습니다. \n\n [별관주차장 서소문공원 (중구 칠패로 5) / [본관주차장] 브라운스톤서울 (중구 청파로 464)";
    const forBus = "• 한국경제신문사•서소문역사공원\n[마을] 서대문06 (서울역 서부광장 -> 아벤티움 한정거장) \n(02516, 02109) \n[간선] 370, 603 / [지선] 7011, 7013A, 7013B, 7017\n\n • 종근당충정로역 (02107)\n간선 172, 472, 603\nKTX(서울역)\nKTX 서울역 3번출구 (서울역 서부광장 방향 / 롯데마트 후문)\n마을버스 서대문 06번 탑승> 한국경제신문사 건너편 하차 (한정거장)";
    const forSubway = "• 2,5호선 충정로역 4번출구 (도보 3분)\n- 출구방향으로 70m 직진 후 횡단보도 건너편 브라운스톤서울 3층\n• 1,4호선 서울역 3번출구 (도보 10분)\n-서울역 서부광장 방향으로 나오신 후 한국경제신문사 방향 이동 후 맞은편";

    const openTitle = "송희수❤️정윤지 결혼합니다.";
    const openDesc = "4월 19일 오후 2시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/5/thumb.jpeg";

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    // 성을 제외하고 이름만 추출 ex.Ga Yeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };
        
    if (!weddingData || !conceptStyle) return <Loading color={"#ff8faf"} loading={loading} />;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"저희 두 사람 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                <MotionWrapper initialY={70}>
                    <Message_Spring goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor} weddingData={weddingData}/>
                    <MotionWrapper initialY={50}>
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum} level={2}/>
                        <Location_Desc forBus={forBus} forSubway={forSubway} forCar={forCar} conceptStyle={conceptStyle}/>
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                    </MotionWrapper>
                </MotionWrapper>
                </MotionWrapper>
                
            </>
            )}
        </div>
    );
};

export default JeongYunJi;
