import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import MotionWrapper from '../component/MotionWrapper';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Loading from '../component/Loading';
import Music from '../component/Music';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Location_Desc from '../component/Location/Location_Desc';
import Footer from '../component/Footer';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';
import GalleryList from '../component/Gallery/GalleryList';

const YooSeulKi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const msg1 = "시월의 어느 가을밤\n운명처럼 다가온 인연,\n이제는 부부의 인연으로 \n평생을 함께 걸어가고자 합니다.";
    const msg2 = "새로운 시작의 순간,\n귀한 걸음 하시어 축복해 주시면\n더 없는 기쁨으로 간직하겠습니다."

    const lng = 128.662109053984;
    const lat = 35.8824102975974;
    
    const placeTelNum = "053-261-2000";

    const forBus = "퀸벨호텔 (구, GS프라자 호텔) 정류장 하차\n618, 719, 805, 808, 818, 836, 980, \n동구1, 동구2, 동구3";
    const forParking = "퀸벨호텔 본관 지상 및 지하주차장 이용\n(외부) 동촌 초/중학교, 도보 3분 거리 위치\n(외부) 제2주차장(홈플러스 동촌점), \n- 셔틀버스 5분 간격 운행\n(외부) 강변 공용주차장, \n- 셔틀버스 5분 간격 운행";
    const forSubway = "지하철 1호선 안심방면 해안역 하차 후 3번 출구 \n (50m 전방)";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다.";

    const openTitle = "인강♥슬기 결혼식에 초대합니다!";
    const openDesc = "25년 4월 5일 14시 30분 퀸벨호텔 프라임홀"
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/21/main.jpg";

    const groomAccountList = [
        {
            role: "신랑",
            name: "박인강",
            bank: "하나은행",
            accountNumber : "600-910556-80007",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7r36To3"
        },
        {
            role: "신랑 부",
            name: "박장순",
            bank: "국민은행",
            accountNumber : "475402-04-031824",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김명숙",
            bank: "농협은행",
            accountNumber : "351-0836-6511-13",
            isKakao : false
        }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "유슬기",
            bank: "농협은행",
            accountNumber : "302-0691-1855-41",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FaaoCFkuL"
        },
        {
            role: "신부 부",
            name: "유백상",
            bank: "농협은행",
            accountNumber : "857-02-050014",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "김미영",
            bank: "농협은행",
            accountNumber : "803-12-108150",
            isKakao : false
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "공주그자체",
            brideAnswer: "제주사랑남"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "공주의노예",
            brideAnswer: "나를따르라"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑해공주",
            brideAnswer: "나도사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "첫째는딸로",
            brideAnswer: "큰일났담스"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "슬기사랑해",
            brideAnswer: "인강고마워"
        }
    ]


    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    useEffect(() => {
        document.onContextMenu = function(){
            return false;
        } 
    },[])

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join('').toLowerCase(); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName; // 결과 문자열 반환
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <> 
                <Music concept={conceptStyle.concept}/>
                <Main_Summer weddingData={weddingData} color={conceptStyle.pointColor} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ff8faf"} dateColor={"#ff8faf"} bottom={"28%"} bottom2={"20%"} dateBottom={"5%"} datefontSize={"20px"}/>
                <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                    <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"}/>
                        <MotionWrapper initialY={70}>
                            <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate = {weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forBus={forBus} forParking={forParking} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default YooSeulKi;
