import React, {useEffect} from 'react';
import lineImg from '../../css/line_hori.png';

const Main_Winter = ({ weddingData , groomNameEn, brideNameEn, width, isFormatWeddingTime}) => {
    useEffect(() => {
        // 핀치 줌 방지 이벤트 등록
        const preventPinchZoom = (e) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventPinchZoom);
    }, []);

    const formatTime = (weddingTime, isFormat) => {
        if(!weddingTime){
            return "";
        }
        const [hour, minute] = weddingTime.split(":").map(Number); // 시간과 분 추출
        const formattedHour = isFormat ? (hour > 12 ? hour - 12 : hour) : hour; // 12시간제로 변환
        return `${formattedHour}:${minute < 10 ? `0${minute}` : minute}`; // "시간:분" 형식으로 반환
    };

    const displayedTime = isFormatWeddingTime ? formatTime(weddingData.time, true) : weddingData.time;

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "space-around"}}>
            <div style={{fontSize: "15px", fontFamily: "Milchella", display: "flex", alignItems: "center", width: "330px", justifyContent: "space-between", margin: "10px 0"}}>
                {weddingData.year.slice(2)} 
                <img src={lineImg} style={{width: "179px"}}/>
                <span style={{display: "flex", width: "53px", justifyContent: "space-between"}}>
                    <p>{weddingData.month}</p>
                    <p>/</p>
                    <p>{weddingData.date}</p>
                </span>
            </div>

            <div style={{width: "300px", }}>
                    <p style={{fontSize: "40px", fontFamily: "Milchella"}}>They are now</p>
                    <p style={{fontSize: "55px", fontFamily: "Vivaldii", marginLeft: "50px"}}>husband</p>
                    <div style={{display: "flex", alignItems: "center", justifyContent:"space-between", transform: "translateY(-19%)"}}> 
                        <span style={{display: "flex", alignItems: "center", width: "141px", justifyContent: "space-between"}}>
                            <p style={{fontSize: "40px", fontFamily: "Milchella"}}>and</p>
                            <p style={{fontSize: "55px", fontFamily: "Vivaldii"}}>wife</p>
                        </span>
                        <p style={{fontSize: "15px", fontFamily: "Milchella"}}>{groomNameEn} & {brideNameEn}</p>
                    </div>
            </div>
            
            <img className='main_winter' src={weddingData.mainImg} alt='Main Image' style={{width: width?width:"256px",  margin: "10px 0", touchAction: "none", userSelect: "none", pointerEvents: "none"}}></img>


            <p style={{fontSize: "22px", fontFamily: "Milchella"}}>{weddingData.year}.{weddingData.month}.{weddingData.date} {weddingData.dayOfWeek} {weddingData.time?displayedTime:""}</p>
        </div>
    );
};

export default Main_Winter;