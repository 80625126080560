import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const KimNaRi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    

    const openTitle = "구현♥나리 결혼합니다.";
    const openDesc = "25년 03월 08일 오후 2시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/73/thumb2.jpeg";

    const lng = 126.92279865019546;
    const lat = 37.5281570589973;

    const placeTelNum = "0507-1346-0181";

    const forBus = "[일반]10\n[마을]영등포10\n[지선]5012, 5633, 6623, 6628\n[간선]N16, 160, 260, 360, 463, 600, \n662, 8600, 8601";
    const forSubway = "9호선) 국회의사당역 3번출구 (도보5분)\n5호선) 여의나루역 1번출구 셔틀버스 이용";
    const forShuttleBus = "여의나루역 1번출구 셔틀버스 수시운행";
    const forParking = "더파티움여의도 지하주차장 건물 내 1시30분 무료 \n(지하1층 연회장 입구 안내 데스크에서 주차권 발행)\n\n만차 시 건너편 '한국기계산업진흥회' 주차장 \n(제2주차장) 이용"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#736449",
        pointColor: "#736449",
        btnBgColor: "#736449",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}


    const groomAccountList = [
        {
            role: "신랑",
            name: "강구현",
            bank: "카카오뱅크",
            accountNumber : "3333-18-5608263",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FO844O2Mw"
        },
        {
            role: "신랑 부",
            name: "강기수",
            bank: "국민은행",
            accountNumber : "631625-01-014036",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김미숙",
            bank: "국민은행",
            accountNumber : "631601-01 -134776",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김나리",
            bank: "카카오뱅크",
            accountNumber : "3333-06-3642316",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8HIgnv7"
        },
        {
            role: "신부 부",
            name: "김현석",
            bank: "하나은행",
            accountNumber : "134-910207-92707",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "박채희",
            bank: "기업은행",
            accountNumber : "461-026299-02-016",
            isKakao : false
        }
    ]

    const msg1 = "'인생이란, 폭풍우가 지나가기를 기다리는 것이 아닌\n 빗속에서 춤추는 법을 배우는 것이다.' \n라는 명언이 있습니다.";
    const msg2 = "함께 있으면 빗속에서도 즐겁고 \n폭풍우 속에서도 행복할 수 있는 \n서로를 만나 평생을 약속하려 합니다.";
    const msg3 = "저희 첫 발걸음,\n설레는 그 시작에\n가장 소중한 분들을 초대합니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "뽀뽀했었지",
            brideAnswer: "당황했었지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "큰일났구나",
            brideAnswer: "이제어쩌지"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "반품은안돼",
            brideAnswer: "교환은되나"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생잘할게",
            brideAnswer: "평생가꿀게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "너만볼거야",
            brideAnswer: "나만봐야해"
        }
    ]

const groomCallInfoList = [
        {
            role: "신랑",
            name: "강구현",
            call: "010 5389 9993"
        },
        {
            role: "신랑 부",
            name: "강기수",
            call: "010 3747 9533"
        },
        {
            role: "신랑 모",
            name: "김미숙",
            call: "010 9067 1678"
        }
    ]
    const brideCallInfoList = [
        {
            role: "신부",
            name: "김나리",
            call: "010 3280 7723"
        },
        {
            role: "신부 부",
            name: "김현석",
            call: "010 6386 7792"
        },
        {
            role: "신부 모",
            name: "박채희",
            call: "010 5899 3546"
        }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#e0cfaf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#ffffff"} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} top={"2%"} bottom={"86%"} bottom2={"78%"} dateBottom={"2%"} />
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"우리 결혼 합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forShuttleBus={forShuttleBus} forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KimNaRi;