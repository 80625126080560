import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';

const YooYeonJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김부순 ♥ 유연진 결혼합니다.";
    const openDesc = "25년 4월 12일 (토) 12시 20분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/67/thumb3.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        // titleColor: "#bd88a8",
        titleColor: "#bf9cd8",
        pointColor: "#bf9cd8",
        btnBgColor: "#bf9cd8",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김부순",
            bank: "신한은행",
            accountNumber : "110-516-521313 ",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "김선해",
            bank: "국민은행",
            accountNumber : "965602-01-256307",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김계희",
            bank: "국민은행",
            accountNumber : "222002-04-225382",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "유연진",
            bank: "농협은행",
            accountNumber : "352-0229-2701-93",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "유영춘",
            bank: "농협은행",
            accountNumber : "302-1150-8904-21",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "권인학",
            bank: "신한은행",
            accountNumber : "110-439-637673",
            isKakao : false
        }
    ]

    const msg1 = "봄 햇살이 함께 했던 지난날,\n여러 해의 봄날 처럼\n앞으로도 햇살같은 봄날을 함께 하려 합니다.";
    const msg2 = "부디 귀한 시간 내주시어 저희의 봄날에 함께\n축복 해주시면 감사하겠습니다.";

    const lng = 127.119355859066;
    const lat =  37.2800229864129;
    
    const placeTelNum = "031-630-3600";

    const forBus = "기흥구청\n - 일반버스 : 99\n - 마을버스 : 21, 21A, 34-1, 35-2, 36, 810-1\n\n기흥역\n - 일반버스 : 10, 55, 66, 66-4, 94, 690, 820\n - 마을버스 : 33, 33-2, 51, 53-1";

    const forSubway = "수인분당선 · 에버라인 기흥역 하차\n- 도보 이용시 : 기흥역 2번출구 도보 400m";
    const forCar = " - 네비게이션 : '기흥ICT밸리' 입력\n- 주소검색 : '용인시 기흥로 58-1' 또는 '용인시 구갈동 \n679' 입력\n\n- 주차안내 : 동시 주차 1,600대 가능 ";
    const forShuttleBus = "수인분당선 · 에버라인 기흥역 1번 출구(15분 간격 운행)";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    // const interviewContent = [
    //     {
    //         question : "결혼을 앞둔 소감이 어때?",
    //         groomAnswer: "잊지 못할 날",
    //         brideAnswer: "한번뿐인 날"
    //     },
    //     {
    //         question : "서로에게 반한 이유는?",
    //         groomAnswer: "운명 같아서",
    //         brideAnswer: "딱! 곰돌이 푸"
    //     },
    //     {
    //         question : "서로에게 하고 싶은 말은?",
    //         groomAnswer: "늘 함께하자",
    //         brideAnswer: "늘 사랑하자"
    //     },
    //     {
    //         question : "유부남/녀 기념 각오 한마디!",
    //         groomAnswer: "말을 잘 듣자!",
    //         brideAnswer: "그만 혼내자!"
    //     },
    //     {
    //         question : "마지막으로 전하고 싶은 이야기는?",
    //         groomAnswer: "축하해줘요",
    //         brideAnswer: "많이많이(하트)"
    //     }
    // ]
    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#bf9cd8"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forShuttleBus={forShuttleBus} forCar={forCar} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default YooYeonJin;
