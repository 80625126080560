import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Information from '../component/Information';
import CallInfo from '../component/CallInfo';
import musicFile from '../asset/music/Lisa-Moonlit_Floor.mp3';
import GalleryList2 from '../component/Gallery/GalleryList2';

const SimHyeYeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이승진 💜 심혜연 결혼합니다.";
    const openDesc = "2025년 6월 15일 일요일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/54/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // spring 퍼플 - 진한 버전
        concept: "SPRING",
        titleColor: "#a772cf",
        pointColor: "#a772cf",
        btnBgColor: "#c6a6de",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#f8f3ff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "이승진",
            bank: "신한은행",
            accountNumber : "110-287-884846",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8xsJB1o"
        },
        {
            role: "신랑 아버지",
            name: "이계송",
            bank: "국민은행",
            accountNumber : "612502-93-119527",
            isKakao : false
        },
        {
            role: "신랑 어머니",
            name: "조경애",
            bank: "국민은행",
            accountNumber : "612502-01-205793",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "심혜연",
            bank: "카카오뱅크",
            accountNumber : "3333-11-9599684",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FZDcEfBvJ"
        },
        {
            role: "신부 아버지",
            name: "심인식",
            bank: "카카오뱅크",
            accountNumber : "3333-32-3794301",
            isKakao : false
        },
        {
            role: "신부 어머니",
            name: "박영미",
            bank: "우리은행",
            accountNumber : "281-000581-02-001",
            isKakao : false
        }
    ]

    const msg1 = "싱그러운 향기가 가득한 여름날 연인이 되어\n세 번의 사계절을 함께 나누었습니다.";
    const msg2 = "평생 좋은 친구로, 사랑하는 연인으로\n서로 아껴주고 사랑하며 살겠습니다.";
    const msg3 = "앞으로 함께 나아갈 수 있는 많은 계절, \n새로운 시작을 축복해 주시면 감사하겠습니다."

    const lng = 126.9755383;
    const lat = 37.5367693;
    
    const placeTelNum = "02-793-2900";

    const forCar = "① 한강대교에서 오시는 경우\n- 서울역 방면으로 오셔서 삼각지역 사거리를 지나서 \n북문으로 우회전 진입\n② 서울역에서 오시는 경우\n- 한강대교 방면으로 오셔서 삼각지역 사거리에서 좌회전 \n후 70M 전방에서 서문으로 좌회전 진입\n③ 이태원방면에서 오시는 경우 \n- 삼각지역 사거리 방향으로 오시다가 동문으로 우회전 \n진입 \n④ 마포(공덕역)에서 오시는 경우\n- 삼각지 고가차도를 넘어 삼각지역 사거리에서 직진 후 \n70M 전방에서 서문으로 좌회전 진입 ";
    const forBus = "① 마을버스 (전쟁기념관 하차)\n- 용산03 \n② 간선버스 (전쟁기념관 하차)\n- 110A, 110B, 421, 740, N72. N75\n③ 삼각지역 하차\n- 421, N75, 100, 150, 151, 152, 500, 501, 502, 504, \n506, 507, 605, 742, 750A, 750B, 752, N15";
    const forSubway = "① 6호선 : 삼각지역 12번 출구 (도보 3분)\n② 4호선 : 삼각지역 1번 출구 (도보 5분)\n③ 1호선 : 남영역 1번 출구 (도보 7분)";
    const forParking = " - 전쟁기념관내 지상, 지하 주차 가능\n- 지상, 지하 주차 1000대 가능\n- 1층 로비 내 태블릿 이용하여 주차권 사전등록 필요 \n(2시간 무료)";

    const account_explain = "멀리서도 축하의 마음을 전하고 싶으신 분들을 위해 \n계좌번호를 안내드립니다.\n\n소중한 축하를 보내주셔서 감사드리며, \n따듯한 마음에 깊이 감사드립니다."

    const informationList =[
        {
            title : "화환 안내",
            content : "화환은 정중히 사양합니다. \n 축하해주시는 따듯한 마음만 \n 감사히 받겠습니다."
        }
    ]


    const brideCallInfoList = [
        {
            role: "신부",
            name: "심혜연",
            call: "010-4840-2747"
        }
    ]


    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이승진",
            call: "010-5067-3641"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#a772cf"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date isBold={true} color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter isPointBold={true} title={"소중한 분들을 초대합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
            <Dday_Spring isPointBold={true} isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                {/* <MotionWrapper initialY={10}> */}
                <GalleryList2 speed={1000} conceptStyle={conceptStyle} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    {/* <MotionWrapper initialY={10}> */}
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    <Information conceptStyle={conceptStyle} informationList={informationList} />
                        {/* <MotionWrapper initialY={10}> */}
                            <Message_Spring isPointBold={true} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            {/* <MotionWrapper initialY={10}> */}
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            {/* </MotionWrapper> */}
                        {/* </MotionWrapper> */}
                    {/* </MotionWrapper> */}
                {/* </MotionWrapper> */}
            </>
            )}
        </div>
    );
};

export default SimHyeYeon;
