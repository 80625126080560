import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import MotionWrapper from '../component/MotionWrapper';
import Loading from '../component/Loading';
import Music from '../component/Music';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Location_Desc from '../component/Location/Location_Desc';
import Footer from '../component/Footer';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';
import GalleryList from '../component/Gallery/GalleryList';

const LeeJiEun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const msg1 = "2017년 3월 시작된 인연,\n2025년 또다시 돌아오는 여덟 번째 봄은\n부부로서 함께 맞이하려 합니다.";
    const msg2 = "서로의 20대를 기억하는 저희가\n이제는 남은 평생을 함께 기억하려 합니다.";
    const msg3 = "그 시작에 귀한 발걸음으로\n축복해 주시면 감사하겠습니다."

    const lng = 126.9755383;
    const lat = 37.5367693;
    
    const placeTelNum = "02-793-2900";

    const forCar = "서울특별시 용산구 이태원로 29 (용산동1가 8)\n\n*주차장 안내\n전쟁기념관내 지상/지하 주차 가능 (1,000대)";
    const forBus = "용산03, 110A, 110B, 421, 740 (전쟁기념관 하차)\n421,100, 150, 151, 152, 500, 501, 502, 504, 506, \n507, 605, 742, 750A, 750B, 752 (삼각지역 하차)";
    const forSubway = "6호선, 4호선 (삼각지역), 1호선 (남영역)";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다.";

    const openTitle = "박종국 ♥ 이지은 결혼합니다.";
    const openDesc = "25년 3월 9일 일요일 오후 5시 30분\n로얄파크컨벤션 파크홀"
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/22/thumb.jpg";


    const groomAccountList = [
        {
            role: "신랑",
            name: "박종국",
            bank: "신한은행",
            accountNumber : "110-380-875164",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FJazI84IB"
        },
        {
            role: "신랑 부",
            name: "박춘석",
            bank: "기업은행",
            accountNumber : "674-003060-01-011",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "성영수",
            bank: "신한은행",
            accountNumber : "110-001-355529",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이지은",
            bank: "하나은행",
            accountNumber : "115-910477-91607",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8VbMmGA"
        },
        {
            role: "신부 부",
            name: "이계근 (*예금주:이지은)",
            bank: "우리은행",
            accountNumber : "1002-652-503303",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "최귀숙",
            bank: "신협은행",
            accountNumber : "132-107-547147",
            isKakao : false
        }
    ]


    const interviewContent = [
        {
            question : "서로가 기억하는 첫 만남?",
            groomAnswer: "또볼수있나",
            brideAnswer: "술그리고술"
        },
        {
            question : "너가 본 나는 어떤 사람이야?",
            groomAnswer: "고마운사람",
            brideAnswer: "언제나내편"
        },
        {
            question : "우리의 8년 연애는 어땠어?",
            groomAnswer: "많이성장함",
            brideAnswer: "예상치못함"
        },
        {
            question : "결혼을 결심하게 된 계기?",
            groomAnswer: "미래를위해",
            brideAnswer: "때가되었다"
        },
        {
            question : "마지막으로 서로에게 하고 싶은 말은?",
            groomAnswer: "더사랑하자",
            brideAnswer: "재밌게살자"
        }
    ]


    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    useEffect(() => {
        document.onContextMenu = function(){
            return false;
        } 
    },[])

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');
                console.log("park", response.data.data)
                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex.Ga Yeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <> 
                <Music concept={conceptStyle.concept}/>
                <Main_Summer weddingData={weddingData} color={conceptStyle.pointColor} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"rgb(80, 93, 137)"} dateColor={"rgb(80, 93, 137)"} top={"4%"} bottom={"28%"} bottom2={"20%"} dateBottom={"7%"} welcomeFontSize={"18px"} topFontSize={"23px"}/>
                <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                <Letter title={"저희 드디어 결혼합니다!"} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                            <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate = {weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring englishAddress={"29, Itawon-ro, Yongsan-gu, Seoul"} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum} googleSearchKeyword={"로얄파크"}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default LeeJiEun;
