import React from 'react';
import "../../css/common.css";
import "../../css/spring/location_spring.css";
import copyImg from '../../css/copy.png';
import KakaoMap from '../KakaoMap';
import kakakoMapLogo from '../../css/logo/logo_kakaomap.png';
import naverMapLogo from '../../css/logo/logo_navermap.png';
import googleMapLogo from '../../css/logo/logo_googlemap.png';

const Location_Spring = ({ isBold, weddingData, conceptStyle, lng, lat, placeTelNum, level, titleFontSize, fontSize, googleSearchKeyword, englishAddress}) => {

    const handleCopyClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert("주소가 복사되었습니다 조심히 오세요🤍");
        } catch (err) {
            console.log(err);
        }
    };

    // goToKakaoNavi 함수 정의
    const goToKakaoNavi = () => {
        if (window.Kakao) {
            window.Kakao.Navi.start({
                name: weddingData.place,
                x: lng,
                y: lat,
                coordType: 'wgs84',
            });
        } else {
            console.error('Kakao SDK 로드 실패');
        }
    };

    return (
        <div className='location_container' style={{fontFamily: conceptStyle.fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: conceptStyle.titleColor, fontFamily: conceptStyle.fontFamilyEn}}>LOCATION</h3>
            <p className='p1_score_14' style={{ paddingTop: "31px", paddingBottom: "10px" , fontFamily: conceptStyle.fontFamilyKo, fontSize: titleFontSize?titleFontSize:"14px", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300}}>{weddingData.place.replace(/\\n/g, '\n')}</p>
            <div className='location_box' style={{color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300}}>
                <div className='address_info'  style={{fontFamily: conceptStyle.fontFamilyKo}}>
                    <p style={{ fontSize: fontSize?fontSize:"11px", marginRight: "2px" }}>{weddingData.address.replace(/\\n/g, '\n')}</p>
                    <img className='copy_img' src={copyImg} alt='Copy' onClick={() => handleCopyClipBoard(weddingData.address.replace(/\\n/g, '\n'))} />
                </div>
                {englishAddress && 
                <div className='address_info'  style={{fontFamily: conceptStyle.fontFamilyKo}}>
                    <p style={{ fontSize: fontSize?fontSize:"11px", marginRight: "2px" }}>{englishAddress}</p>
                    <img className='copy_img' src={copyImg} alt='Copy' onClick={() => handleCopyClipBoard(englishAddress)} />
                </div>
                }
                <p style={{ fontSize: "10.5px" }}>TEL. {placeTelNum}</p>
            </div>

            <div style={{ display: "block", flexDirection: "column" }}>
                <KakaoMap lng={lng} lat={lat} level={level}/>
            </div>

            <div className='navi_box'>
                <div className='navi_btn' onClick={goToKakaoNavi}>
                    <img className='navi_logo_img' src={kakakoMapLogo} alt='Kakao Map Logo' style={{ width: "14px", marginRight: "5px" }} />
                    <p>카카오 네비</p>
                </div>
                <div className="navi_btn"
                    onClick={() => window.location.href = `https://map.naver.com/p/search/${weddingData.address}`}>
                    <img className='navi_logo_img' src={naverMapLogo} alt='Naver Map Logo' style={{ width: "11px" }} />
                    <p>네이버 지도</p>
                </div>
                {googleSearchKeyword && (
                    <div className="navi_btn"
                        onClick={() => window.location.href = `https://www.google.co.kr/maps/search/${googleSearchKeyword}`}>
                        <img className='navi_logo_img' src={googleMapLogo} alt='Google Map Logo' style={{ width: "15px" }} />
                        <p>Google Map</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Location_Spring;
