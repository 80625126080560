import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import MotionWrapper from '../component/MotionWrapper';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Loading from '../component/Loading';
import Music from '../component/Music';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Location_Desc from '../component/Location/Location_Desc';
import Footer from '../component/Footer';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';
import GalleryList from '../component/Gallery/GalleryList';

const MinHaJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const msg1 = "아름다운 봄날,\n3년의 시간 끝에 소중한 결실을 맺게 되었습니다.\n이제 두 사람이 \n부부라는 이름으로 마주보기 위해\n소중한 분들을 모시고\n사랑의 약속을 하려고 합니다.";
    const msg2 = "한 곳을 바라보며\n첫 발을 내밀은 자리\n그 시작에 함께해 주시면 \n더 없는 기쁨으로 간직하겠습니다.";

    const lng = 127.080291104308;
    const lat = 37.0655111093565;
    
    const placeTelNum = "031-611-1414";

    const forCar = "네이게이션에 “국제웨딩컨벤션” 입력\n\n주차장: 국제컨벤션센터 주차장 이용\n(국제대학교 전체 주차 시설 이용 가능)\n국제대학교 정문 쪽에 위치한 가장 큰 건물\n무료 주차";
    const forBus = "5-3,7,88A,88B,99 \n코오롱하늘채아파트 동문 or 북문 하차";
    const forSubway = "1호선 서정리역 하차 -> 셔틀버스 국제웨딩컨벤션 도착\nSRT 지제역 하차 -> 1호선 서정리역 하차 \n-> 셔틀버스 국제웨딩컨벤션 도착";
    const forShuttle = "1호선 서정리역 출발 -> 국제대학교,웨딩컨벤션\n서정리역 출발:매시 5분,35분\n국제웨딩컨벤션 출발:매시 20분,50분\n(예식 1시간 전부터 예식 2시간 후까지 운행)"

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다.";

    const openTitle = "신경도♥민하진 결혼합니다.";
    const openDesc = "4월 5일 오전 11시 30분"
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/30/thumb.jpg";

    const groomAccountList = [
        {
            role: "신랑",
            name: "신경도",
            bank: "신한은행",
            accountNumber : "110-314-201557",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8vdfyHz"
        },
        {
            role: "신랑 부",
            name: "신은호",
            bank: "우리은행",
            accountNumber : "139-08-188328",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "장채순",
            bank: "신한은행",
            accountNumber : "904-04-070763",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "민하진",
            bank: "신한은행",
            accountNumber : "110-413-273838",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FJtcFWtpP"
        },
        {
            role: "신부 부",
            name: "민정식",
            bank: "하나은행",
            accountNumber : "152-910219-03907",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "임수희",
            bank: "국민은행",
            accountNumber : "584102-01-349260",
            isKakao : false
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "어제 아니야?",
            brideAnswer: "영화봤잖아"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "나는 끝났다",
            brideAnswer: "나도 끝났다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "쌍둥이 낳자",
            brideAnswer: "지금처럼만"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "없어",
            brideAnswer: "똑같지"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "하징사랑해",
            brideAnswer: "뿡도사랑해"
        }
    ]


    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        // titleColor: "#FF8A83",
        titleColor: "#FF859A",
        pointColor: "#FF859A",
        btnBgColor: "#FF859A",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    useEffect(() => {
        document.onContextMenu = function(){
            return false;
        } 
    },[])

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');
                console.log("park", response.data.data)
                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF859A"} loading={loading} />
            ) : (
                <> 
                <Music concept={conceptStyle.concept}/>
                <Main_Summer weddingData={weddingData} color={"#ffffff"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#FF859A"} dateColor={"#FF859A"} top={"1%"} bottom={"25%"} bottom2={"17%"} dateBottom={"5%"} />
                <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                    <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                            <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate = {weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} forShuttle={forShuttle} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default MinHaJin;
