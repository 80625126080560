import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Main_Spring from '../component/Spring/Main_Spring';

const HwangYeJi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "김재헌 황예지, 저희 결혼합니다! 🤵🏻💍👰🏻";
    const openDesc = "4월20일 일요일 10시\n아시아드웨딩컨벤션 그레이스홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/64/thumb.jpg";

    const placeTelNum = "032-765-6000";

    const forCar = "네비게이션 : '아시아드웨딩컨벤션' 또는 '염곡로 725' 입력"
    const forShuttleBus = "셔틀버스 운행 (15분 간격 운행)\n① 공항철도, 인천 2호선 검역 1번출구 횡단보도 건너편 승차\n② 인천 2호선 아시아드경기장역 3번출구 앞 승차";
    const forBus = "- 우성아파트 [42717] : 24-1, 70, 인천e음86, 111, 111B, \n302, 310\n- 우성아파트 [42718] : 24-1, 70, 595, 인천e음86, 111, \n111B, 302, 302B, 308,310\n\n- 인천아시아드주경기장(동문) [89354] : 3-2, 71, \n인천e음86\n- 인천아시아드주경기장(동문) [89359] : 3-2, 71, \n인천e음86";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김재헌",
            bank: "카카오뱅크",
            accountNumber : "3333-24-1321981",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej826kvu0"
        },
        {
            role: "신랑 부",
            name: "김선겸",
            bank: "농협은행",
            accountNumber : "091-12-384740",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "공미선",
            bank: "기업은행",
            accountNumber : "351-004367-02-015",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "황예지",
            bank: "국민은행",
            accountNumber : "065902-04-290743",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej826kvu0"
        },
        {
            role: "신부 부",
            name: "황리규",
            bank: "KDB산업은행",
            accountNumber : "02092293520310",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej83ZNFXG"
        }
    ]

    const parents = [
        {groom : "김선겸 . 공미선의 장남"},
        {bride : "황리규의 장녀"}
    ]


    const msg1 = "그저 존@재@만으로 전부가 되어준 내 사람과\n사랑은 @헌@신의 또 다른 이름임을 잊지 않고\n부부의 @예@를 갖추어 비로소 하나가 되는 날,\n흐드러@지@는 봄 햇살에 연분홍빛 춤출 무렵";
    const msg2 = "저희 두 사람도 인생의 봄을 맞이하고자 합니다.\n\n두 사람의 시작을 가까이 자리 하시어\n축복해 주시면 더없는 기쁨으로 간직하며 살겠습니다.";

    const lng = 126.66768548283572;
    const lat = 37.548716982997554;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "비비탄만점",
            brideAnswer: "오빠미뿜뿜"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "기다려왔어",
            brideAnswer: "너무행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이사랑해",
            brideAnswer: "나도사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "행복만줄게",
            brideAnswer: "나이제너꺼"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "모든분들께",
            brideAnswer: "감사합니다♥"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    {/* <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={"#ff8faf"}/> */}
                    <Main_Winter isFormatWeddingTime={true} width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} parents={parents} weddingData={weddingData} isFormatWeddingTime={true}/>
                    <Letter title={"저희 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring isFormatWeddingTime={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forShuttleBus={forShuttleBus} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default HwangYeJi;