import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import "../../css/spring/dday_spring.css";
import RSVPModal from '../\bModal/RSVPModal';

const Dday_Spring = ({ groomName, brideName, weddingData, conceptStyle, isAttendFromClose, isFormatWeddingTime, isBold, isPointBold}) => {
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
    const [isSecondModal, setIsSecondModal] = useState(false); // 두 번째 모달 상태
    const [timeLeft, setTimeLeft] = useState({});

    const calculateTimeLeft = useCallback(() => {
        const targetDateTime = moment.tz(
            `${weddingData.weddingDate} ${weddingData.time}`,
            "YYYY-MM-DD HH:mm",
            "Asia/Seoul"
        );
    
        const now = moment.tz("Asia/Seoul");
        const difference = targetDateTime.diff(now);
    
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
        return timeLeft;
    }, [weddingData.weddingDate, weddingData.time]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    // 스크롤 이벤트 처리
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const componentPosition = document.querySelector('.dday_container').offsetTop;

            if (scrollY >= componentPosition - window.innerHeight / 2) {
                if (!isAttendFromClose && !isSecondModal && !localStorage.getItem('secondModalSubmitted') && !localStorage.getItem('tmpModalClosed')) {
                    setIsModalOpen(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isSecondModal]);

    useEffect(() => {
        localStorage.removeItem('tmpModalClosed');
    }, []);

    // 모달 닫기
    const closeModal = () => {
        setIsModalOpen(false);
        setIsSecondModal(false);
        localStorage.setItem('secondModalSubmitted', 'true');
    };

    // 모달 일시 닫기 
    const tmpCloseModal = () => {
        setIsModalOpen(false);
        setIsSecondModal(false);
        localStorage.setItem('tmpModalClosed', 'true');
    }

    const goToSecondModal = () => {
        setIsSecondModal(true);
    };
    
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'; // 스크롤 비활성화
        } else {
            document.body.style.overflow = 'auto'; // 스크롤 활성화
        }
        return () => {
            document.body.style.overflow = 'auto'; // 컴포넌트가 언마운트될 때 스크롤 활성화
        };
    }, [isModalOpen]);
    
    return (
        <div className='dday_container'>
            <img className='dday_img' src={weddingData.subImg} alt='Dday Image'></img>
        
            <div className='dday_box' style={{fontWeight : isBold ? 600 : 300}}>
                <div className='text_box' style={{fontFamily: conceptStyle.fontFamilyKo}}>
                    <p>{groomName}  ♥︎  {brideName} 결혼식이 </p>
                    <p style={{color : `${conceptStyle.pointColor}`, fontWeight: isPointBold?600:300}}>{timeLeft.days}일 </p>
                    <p>남았습니다.</p>
                </div>

                <div className='date_container_wrap'>
                    <div className='date_container'>
                        <div className='date_wrap'>
                            <div className='date_box'>{timeLeft.days}</div>
                            <p>DAYS</p>
                        </div>
                        <div className='date_wrap'>
                            <div className='date_box'>{timeLeft.hours}</div>
                            <p>HOURS</p>
                        </div>
                        <div className='date_wrap'>
                            <div className='date_box'>{timeLeft.minutes}</div>
                            <p>MINUTES</p>
                        </div>
                        <div className='date_wrap'>
                            <div className='date_box'>{timeLeft.seconds}</div>
                            <p>SECONDS</p>
                        </div>
                    </div>
                </div>
            </div>
            {!isAttendFromClose &&
                <RSVPModal isBold={isBold?isBold:false} isModalOpen={isModalOpen} isSecondModal = {isSecondModal} tmpCloseModal = {tmpCloseModal} closeModal = {closeModal} goToSecondModal={goToSecondModal} conceptStyle={conceptStyle} weddingData={weddingData} isFormatWeddingTime={isFormatWeddingTime}/>
            }
        </div>
    );
};

export default Dday_Spring;