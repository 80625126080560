import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const LeeJiYeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "유준영 ♥ 이지연 결혼합니다.";
    const openDesc = "2025.05.17 토요일 11시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/80/thumb.jpg";


    const lng = 126.92373324306799;
    const lat = 37.51025473700294;

    const placeTelNum = "02-844-0336";

    const forBus = "간선 : 150, 461, 505, 753\n지선 : 5531, 5534, 5623, 5633, 6514, 6713\n광선 : M5609\n마을 : 영등포07";
    const forSubway = "1호선 대방역 5번출구(도보5분)\n7호선 보라매역 7번출구(도보15분)\n신림선 대방역 6번출구(도보5분)\n신림선 서울지방병무청역 2번출구(도보9분)";
    const forParking = "공군호텔 내 지하주차장 (3시간 무료주차 제공)";
    const forCar = "'서울특별시 영등포구 여의대방로 259' 또는\n'공군호텔' 검색";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}


    const msg1 = "2025년 따뜻한 봄날,\n저희 두 사람이 새로운 출발의 첫 걸음을 내딛습니다.";
    const msg2 = "바른 뜻으로 올바르게 살 수 있도록\n축하와 격려해주신다면\n더 없는 기쁨으로 깊이 간직하며 살겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "유준영",
            bank: "국민은행",
            accountNumber : "933502-00-182309",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "유해철",
            bank: "국민은행",
            accountNumber : "348301-04-162317",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김미희",
            bank: "농협은행",
            accountNumber : "1104-12-045736",
            isKakao : false
        }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "이지연",
            bank: "카카오뱅크",
            accountNumber : "3333-07-4308081",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "이상학",
            bank: "농협은행",
            accountNumber : "217060-56-091351",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "김남숙",
            bank: "기업은행",
            accountNumber : "308-094092-01-016",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "찾았다요놈",
            brideAnswer: "잡았다요놈"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "우리잘살자",
            brideAnswer: "너무설렌다"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생내꾸해",
            brideAnswer: "평생행복해"
        },
    ]

const groomCallInfoList = [
        {
            role: "신랑",
            name: "최태규",
            call: "010 8834 4976"
        }
        // ,
        // {
        //     role: "신랑 부",
        //     name: "강기수",
        //     call: "010 3747 9533"
        // },
        // {
        //     role: "신랑 모",
        //     name: "김미숙",
        //     call: "010 9067 1678"
        // }
    ]
    const brideCallInfoList = [
        {
            role: "신부",
            name: "김보현",
            call: "010 2186 7191"
        }
        // ,
        // {
        //     role: "신부 부",
        //     name: "김현석",
        //     call: "010 6386 7792"
        // },
        // {
        //     role: "신부 모",
        //     name: "박채희",
        //     call: "010 5899 3546"
        // }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
        // 7FAD2A 그린
        // FA7384 핑크
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#000000"} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#ffffff"} dateColor={"#000000"} bottom={"24%"} bottom2={"16%"} dateBottom={"84%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"소중한 분들을 초대합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default LeeJiYeon;