import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import babyStepMusicFile from "../asset/music/babyStep.mp3";
import GalleryList from '../component/Gallery/GalleryList';

const KimKaYoung2 = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const openTitle = "최정호 ♥ 김가영의 결혼식에 초대합니다.";
    const openDesc = "2025년 03월 09일(일) 오후 12시"
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/32/thumb.jpg";

    const msg1 = "함께 맞이하는 일곱 번째 봄";
    const msg2 = "평생을 함께할 큰 사랑을 약속하고자 합니다.\n귀한 걸음 하시어 축복해 주시면 감사하겠습니다.";

    const lng = 129.100265672;
    const lat = 35.145592665;
    
    const placeTelNum = "051-922-2200";

    const forBus = "1. 대남교차로 & 남천역 하차 후 셔틀버스 이용 \n (20, 39, 40, 41, 42, 51, 83-1, 83, 108, 131, 155, \n 1001, 1003) \n\n 2. 동원 보라 아파트 하차 ▶ 도보 5분 소요 \n (38, 583)";
    const forParking = "본 건물 B5층, B1층, 지상 주차장, 타워 주차장 이용";
    const forSubway = "2호선 남천역 4번출구 ▶ 셔틀버스 이용";
    const forTrain = "부산역 하차 시 ▶ 지하철 1호선(노포방면) \n ▶ 서면역 2호선 환승(장산방면) \n ▶ 남천역 하차 4번 출구 \n ▶ 셔틀버스 이용"
    const forShuttleBus ="부산 지하철 2호선 남천역 4번 출구로 나와서 더샵 입구 \n(103동 근처)에서 승차\n\n* 15-20분 간격으로 운행\n단, 당일 도로 상황에 따라 늦어질 수 있는 점 \n양해 부탁드립니다."

    const account_explain = ""

    const parents = [
        {groom : "최한익 · 이행선의 장남"},
        {bride : "김주석 · 박점순의 차녀"}
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "최정호",
            bank: "농협은행",
            accountNumber : "302-0188-1201-11",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej81bYF9i"
        },
        {
            role: "신랑 부",
            name: "최한익",
            bank: "우리은행",
            accountNumber : "041-174342-02-001",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FeLYpBMxK"
        },
        {
            role: "신랑 모",
            name: "이행선",
            bank: "우리은행",
            accountNumber : "041-103579-12-030",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FWgO7fo9H"
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김가영",
            bank: "부산은행",
            accountNumber : "084-12-096145-3",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FZclbW2so"
        },
        {
            role: "신부 모",
            name: "박점순",
            bank: "부산은행",
            accountNumber : "112-2085-4766-05",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FWgO7fo9H"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={babyStepMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"4px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forParking={forParking} forBus={forBus} forSubway={forSubway} forTrain={forTrain} forShuttleBus={forShuttleBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default KimKaYoung2;
