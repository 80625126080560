import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';
import GalleryList from '../component/Gallery/GalleryList';

const JangSooYeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "강성규🖤장수연 결혼합니다.";
    const openDesc = "03월 29일 11시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/18/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // #c42152
        // #ca5069
        // #ce5249
        concept: "SPRING",
        titleColor: "#ce5249",
        pointColor: "#ce5249",
        btnBgColor: "#ce5249",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "강성규",
            bank: "국민은행",
            accountNumber : "941602-00-216311",
            isKakao : false,
        },
        {
            role: "신랑 부",
            name: "강봉기",
            bank: "SC제일은행",
            accountNumber : "611-20-081180",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김주영",
            bank: "국민은행",
            accountNumber : "666201-01-455174",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "장수연",
            bank: "신협",
            accountNumber : "137-009-786909",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "허진선",
            bank: "기업은행",
            accountNumber : "185-022916-03-014",
            isKakao : false
        }
    ]


    const interviewContent = [
        // {
        //     question : "우리의 설레였던 첫 만남 기억해?",
        //     groomAnswer: "너무 예뻤어",
        //     brideAnswer: "어떻게 잊어"
        // },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨려요",
            brideAnswer: "설레 죽겠다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "나도 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "잘해줄게요",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이♥"
        }
    ]

    const msg1 = "아주 작은 인연이 저희를 연인으로 만들었고\n늘 그 인연으로 저희가 하나가 됩니다.";
    const msg2 = "작은 사랑으로 하나의 커다란 열매를 맺고\n이제 또 다른 모습으로 사랑하고자 하는 두 사람을 \n오셔서 지켜봐 주시고 축하해 주십시오.";

    const lng = 126.827921256137;
    const lat = 37.3149306266479 ;

    const placeTelNum = "031-487-8100";

    const forCar = "🚘 본 건물 및 맞은편 주차타워 2시간 무료 이용"
    const forBus = "🚌 고잔역 뒤 정류장 하차 (97, 98, 99-1, 500번)";
    const forSubway = "🚉 4호선 고잔역(고대안산병원) 2번출구에서 \n왼쪽 대로변 따라 200m 전방에 위치";
    
    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ce5249"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default JangSooYeon;
