import React, { useState, useEffect } from 'react';

const ReplyTable = ({replyData}) => {

    return (
        <div>
        <table border="1" style={{ width: '100%'}}>
            <thead>
            <tr>
            <th>이름</th>
            <th>전화번호</th>
            <th>참석여부</th>
            <th>식사여부</th>
            <th>참석측</th>
            <th>참석인원</th>
            <th>답변일자</th>
        </tr>
        </thead>
        <tbody>
            {replyData && Array.isArray(replyData) ? (
                        replyData.map((reply, index) => (
                            <tr key={index}>
                                <td>{reply.data.name}</td>
                                <td>{reply.data.phoneNumber}</td>
                                <td>{reply.data.willAttend === 'Y' ? 'O' : 'X'}</td>
                                <td>{reply.data.willHaveMeal === 'Y' ? 'O' : 'X'}</td>
                                <td>{reply.data.weddingPartyRole === 'BRIDE' ? '신부' : '신랑'}</td>
                                <td>{reply.data.companionCount}</td>
                                <td>{new Date(reply.createdAt).toLocaleString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8">No data available</td>
                        </tr>
                    )}
            </tbody>
        </table>
        </div>
    );
};

export default ReplyTable;
