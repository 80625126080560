import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import Interview_Spring from '../component/Spring/Interview_Spring';
import GalleryList from '../component/Gallery/GalleryList';
import music from '../asset/music/Taylor Swift-03-Lover-Lover-192.mp3'

const LeeGaHyang = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "이호진❤️이가향 결혼합니다.";
    const openDesc = "4월 19일 오후 3시 30분, 셀럽앤어셈";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/26/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "이호진",
            bank: "신한은행",
            accountNumber : "110-497-733627",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7lFUCAM"
        },
        {
            role: "신랑 모",
            name: "이소녀",
            bank: "농협은행",
            accountNumber : "21602256013272",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이가향",
            bank: "신한은행",
            accountNumber : "110-485-554614",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FcJxM7bIF"
        },
        {
            role: "신부 부",
            name: "이종화",
            bank: "SC은행",
            accountNumber : "73320010209",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "장미정",
            bank: "농협은행",
            accountNumber : "65301456017637",
            isKakao : false
        }
    ]


    const interviewContent = [
        {
            question : "우리가 사귀게 된 날을 기억해?",
            groomAnswer: "용기냈었지",
            brideAnswer: "두근거렸어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "벌써행복해",
            brideAnswer: "살짝설렌다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "오늘도예뻐",
            brideAnswer: "언제나멋져"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "아껴줄게요",
            brideAnswer: "힘이될게요"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "감사해요♥",
            brideAnswer: "잘살게요♥"
        }
    ]

    const msg1 = "함께 맞이하는 4번째 봄, 저희 결혼합니다.\n평생 좋은 친구로, 사랑하는 연인으로\n서로 아껴주고 사랑하며 살겠습니다.";
    const msg2 = "저희 두 사람의 새로운 시작에 귀한 걸음 하시어\n따뜻한 마음으로 축복해주시면 감사하겠습니다.";

    const lng = 127.0344008;
    const lat = 37.5174273;

    const placeTelNum = "02-545-2222";

    const forBus = "간선버스 : 141 / 직행버스 : 3600 / 공항버스 : 6703";
    const forCar = "네비게이션 검색명 : ‘건설회관’ 또는 ‘셀럽앤어셈’\n도로명 주소 : 서울 강남구 언주로 711";
    const forSubway = "- 7호선 학동역 10번출구\n- 수인분당&7호선 강남구청역 3번출구";
    const forRentBus = "- 목포시 버스터미널 건너편 고가도로 아래에서 \n 오전 9:30에 출발";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();

        // 핀치 줌 방지 이벤트 등록
        const preventPinchZoom = (e) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventPinchZoom);
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={music}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"} gap={"4px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} forRentBus={forRentBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default LeeGaHyang;
