import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';

const ParkAeRi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "수형, 애리 결혼합니다 ♥";
    const openDesc = "4월19일 토요일 15시30분 헤리움웨딩홀 단독홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/36/thumb.jpg";
    
    const placeTelNum = "051-943-1004";

    const forBus = "A 서면한전(범내골방면)\n5-1,10,17,23,29,43,52,57,68,80,81,83,83-1,86,88,99,103,\n106,108,111,138,167,583[1000,1004]-심야,1009-1\n\nB 서면한전(서면역방면,서면지하상가방면)\n17,20,23,24,52,67,68,81,83,83-1,86,88,99,108,138,160,\n167,169-1\n\nC 경남공고(서면한전방면)\n20,169-1 [심야 - 1002]\n\nD 경남공고(부전도서관방면)\n5-1,10,29,43,54,57,80,103,111,133";
    const forSubway = "1호선 범내골역 8번 출구에서 도보5분\n2호선 전포역 3번 출구에서 도보 5분";
    const forCar = "경부고속도로 \n- 부산톨게이트>번영로>문현교차로>문전교차로(신천대로)>CJ오거리(동천로)\n\n남해고속도로 \n- 서부산톨게이트>동서고가로>동성로>동천로";
    const forParking = "상가주차장-지하1층 (무료주차 2시간)";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#ff8faf",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김수형",
            bank: "카카오뱅크",
            accountNumber : "3333-12-7872745",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "김호식",
            bank: "국민은행",
            accountNumber : "565002-01-437126",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "양미옥",
            bank: "하나은행",
            accountNumber : "833-910432-71907",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박애리",
            bank: "카카오뱅크",
            accountNumber : "3333-06-2671161",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "박용범",
            bank: "농협은행",
            accountNumber : "801161-56-026097",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "심점숙",
            bank: "농협은행",
            accountNumber : "801161-56-037641",
            isKakao : false
        }
    ]

    const msg1 = "특별한 것을 하지 않아도\n함께하면 행복한 사람과 모든 것을\n새롭게 시작하는 봄날에 결혼합니다.";
    const msg2 = "봄날의 햇살처럼 밝고 따뜻하게 살겠습니다.";
    const msg3 = "평생의 친구가 될 저희의 첫 날에 초대합니다.\n귀한 걸음 하시어 그 시작의 자리에 함께 해주시면 감사하겠습니다."

    const lng = 129.060303359759;
    const lat = 35.151843130536;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 데이트 기억해?",
            groomAnswer: "잊을수없지",
            brideAnswer: "어떻게잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무떨려요",
            brideAnswer: "완전신나요"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "행복합시다",
            brideAnswer: "반품안돼요"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "꽃길만걷자",
            brideAnswer: "잘해줄게요"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "우리결혼을",
            brideAnswer: "축하해줘요"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall weddingData={weddingData} />
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"4px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forCar={forCar} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default ParkAeRi;