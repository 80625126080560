import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';

const ParkMiYeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "이건우♥박미연 결혼합니다.";
    const openDesc = "25년 4월 12일 토요일 12:40\n대전 루이비스컨벤션 1F 그레이스홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/59/thumb4.jpeg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });


    const conceptStyle = {
        // 인디핑크 : #c77e83
        // #e89ba1
        // 빈티지연핑크 : #edacb1
        // 로즈 : #cf5f68
        // 연로즈 : #e36d8c
        // 소라 : #7f86a3

        // spring 핑크B
        concept: "SPRING",
        titleColor: "#B86D82",
        pointColor: "#B86D82",
        btnBgColor: "#B86D82",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "이건우",
            bank: "국민은행",
            accountNumber : "453202-04-297550",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "이종세",
            bank: "신한은행",
            accountNumber : "110-074-655034",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "박종석",
            bank: "신한은행",
            accountNumber : "110-486-046565",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박미연",
            bank: "국민은행",
            accountNumber : "933502-00-570593",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FaVTYaGID"
        },
        {
            role: "신부 부·모",
            name: "박병열·김점미",
            bank: "하나은행",
            accountNumber : "620-164075-843",
            isKakao : false
        }
    ]
    
    const msg1 = "추운 겨울 둘이 되고\n또 일 년 셋이 되었습니다.";
    const msg2 = "어엿한 가정을 이루고 듬직한 아빠, 다정한 엄마가 된\n자랑스러운 아들, 사랑스러운 딸이\n소중한 분들을 모시고 사랑의 선서를 하려고 합니다.";
    const msg3 = "소중한 시간 내주셔서 자리를 빛내주시면 감사드리겠습니다.";

    const lng = 127.39804667277899;
    const lat = 36.42475192911509;
    
    const placeTelNum = "042-933-2500";

    const forBus = "• 유성시외터미널에서 오는 법 : 704번 버스 승차 \n  → 롯데마트 대덕점 하차 → 도보 15분\n\n• 복합터미널에서 오는 법\n: 701번 버스 승차 → 대덕밸리용신교 하차 → 도보 5분\n: 802번 버스 승차 → 롯데마트 대덕점 하차 → 도보 15분";
    const forSubway = "• 신탄진역에서 오는 법 : 705번 버스 승차 \n → 현대아울렛북문 하차 → 도보 5분\n\n• 대전역에서 오는 법 : 701번 버스 승차 \n→ 대덕밸리용신교 하차 → 도보 5분";
    const forParking = "• 호텔&컨벤션 지상주차장과 현대아울렛 남문 북문 지하, 지상 주차타워 다 가능하시며 \n웨딩홀 입구 키오스크에서 차 번호 등록 하시면 총 2시간 무료 주차 가능하십니다.\n(만차 시 순환적으로 임시 주차장 안내)\n\n• 2시간 이후로는 10분당 1,000원 발생 되시는 점 \n참고 부탁드립니다.";

    const account_explain = ""

    // const interviewContent = [
    //     {
    //         question : "결혼을 앞둔 소감이 어때?",
    //         groomAnswer: "잊지 못할 날",
    //         brideAnswer: "한번뿐인 날"
    //     },
    //     {
    //         question : "서로에게 반한 이유는?",
    //         groomAnswer: "운명 같아서",
    //         brideAnswer: "딱! 곰돌이 푸"
    //     },
    //     {
    //         question : "서로에게 하고 싶은 말은?",
    //         groomAnswer: "늘 함께하자",
    //         brideAnswer: "늘 사랑하자"
    //     },
    //     {
    //         question : "유부남/녀 기념 각오 한마디!",
    //         groomAnswer: "말을 잘 듣자!",
    //         brideAnswer: "그만 혼내자!"
    //     },
    //     {
    //         question : "마지막으로 전하고 싶은 이야기는?",
    //         groomAnswer: "축하해줘요",
    //         brideAnswer: "많이많이(하트)"
    //     }
    // ]
    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#B86D82"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"저희 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList2 conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default ParkMiYeon;
