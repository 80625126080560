import React, { useState } from 'react';
import axios from 'axios';
import ReplyTable from '../component/Admin/ReplyTable';

const Admin = () => {
    const [accountId, setId] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [replyData, setReplyData] = useState(null); // Store reply data

    const handleLogin = async () => {
        const requestBody = {
            accountId,
            password,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/_api/v1/auth/admin/login`, requestBody, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200) {
                setIsLogin(true);
                const replyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/_api/v1/reply/${accountId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });

                if (replyResponse.status === 200) {
                    setReplyData(replyResponse.data);
                    console.log(replyResponse)
                }
            }
        } catch (error) {
            alert('로그인 실패 😭. 다시 시도해주세요.');
            console.error('에러 발생:', error.response?.data || error.message);
        }
    };

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {!isLogin ? (
                // Show login form when not logged in
                <div style={{ width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span style={{ margin: '10px 0' }}>
                        <p>ID</p>
                        <input
                            type="text"
                            placeholder="아이디"
                            value={accountId}
                            onChange={(e) => setId(e.target.value)}
                            style={{ border: '1px #c8c8c8 solid', borderRadius: '10px', padding: '2px 6px' }}
                        />
                    </span>

                    <span>
                        <p>Password</p>
                        <input
                            type="password"
                            placeholder="비밀번호"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ border: '1px solid #c8c8c8', borderRadius: '10px', padding: '2px 6px' }}
                        />
                    </span>

                    <button
                        className="spring_btn"
                        style={{ width: '100px', margin: '30px auto' }}
                        onClick={handleLogin}
                    >
                        로그인
                    </button>
                </div>
            ) : (
                // Show success message and reply data when logged in
                <div>
                    <p>로그인 성공 🎉</p>
                    {replyData ? (
                        <div>
                            {/* <h3>Reply Data:</h3>
                            <pre>{JSON.stringify(replyData, null, 2)}</pre>  */}
                            
                            <ReplyTable replyData={replyData}/>
                        </div>
                    ) : (
                        <p>답변을 가져오는 중...</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Admin;
