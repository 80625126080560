import React, { useState, useEffect } from 'react';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Account_Spring from '../component/Spring/Account_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import axios from 'axios';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';


const Summer = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const weddingDate = "2025-03-09";

    const msg1 = "싱그러운 여름 향기가 가득한 날,";
    const msg2 = "두 사람이 사랑으로 만나 \n 부부라는 이름으로 마주보기 위해 \n 소중한 분들을 모시고 \n사랑의 약속을 하려고 합니다.";
    const msg3 = "한 곳을 바라보며 \n첫 발을 내밀은 자리 \n 그 시작에 함께해 주시면 \n 더 없는 기쁨으로 간직하겠습니다.";

    const lng = 129.100265672;
    const lat = 35.145592665;
    
    const placeTelNum = "051-668-7979";

    const forCar = "네비게이션 “부산광역시 남구 황령대로 401-9” 입력";
    const forBus = "남천역 4번 출구 남천더샵프레스티지 103동 인근\n셔틀버스 무료 운행 (배차간격 10-20분)";
    const forParking = "그랜드모먼트 건물 지하 5층, 지하1층, 지하주차장, 타워주차장";
    const forSubway = "부산 2호선 남천역 4번 출구 (도보 10분)";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
            concept: "SUMMER",
            titleColor: "#505D89",
            pointColor: "#505D89",
            btnBgColor: "#505D89",
            fontColor: "#fff",
            btnFontColor: "#fff",
            chatBgColor: "#F5F8FF",
            fontFamilyKo: "SCDream3",
            fontFamilyEn: "Edensor"
    }

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "어떻게 잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨려요",
            brideAnswer: "설레 죽겠다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "나도 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "잘해줄게요",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이♥"
        }
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "최정호",
            bank: "농협은행",
            accountNumber : "789-45665-552-4",
            isKakao : true,
            kakaoUrl: ""
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김가영",
            bank: "부산은행",
            accountNumber : "123-456-7899",
            isKakao : true,
            kakaoUrl: ""
        }
    ]

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join('').toLowerCase(); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName; // 결과 문자열 반환
    };

    // 성을 제외하고 이름만 추출 ex.Ga Yeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <> 
                <Music concept={conceptStyle.concept}/>
                <Main_Summer weddingData={weddingData} color={conceptStyle.pointColor} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#fff"} dateColor={"#fff"} bottom={"28%"} bottom2={"20%"} dateBottom={"7%"} />
                <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                <Letter title={"우리 결혼 합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                    <Gallery_Spring color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate = {weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forBus={forBus} forParking={forParking} forSubway={forSubway} forCar={forCar} conceptStyle={conceptStyle}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default Summer;