import React from 'react';
import MotionWrapper from './MotionWrapper';

const Save_Date = ({ color, isBorder, marginTop, marginBottom, fontSize, isBold }) => {
    return (
        <div style={{ color: `${color}`, display: "flex", justifyContent: "center", marginTop: `${marginTop}`,  marginBottom: `${marginBottom}`, }}>
            <MotionWrapper>
                <p 
                    style={{
                        width: "140px",
                        fontSize: `${fontSize}`,
                        padding: "8px",
                        textAlign: "center",
                        fontFamily: "QESTERO",
                        border: isBorder ? '1px solid currentColor' : 'none',
                        borderRadius: isBorder ? '50%' : '0',  // 모서리 50% 둥글게 설정
                        fontWeight: isBold?600:300
                    }}
                >
                    SAVE THE DATE
                </p>
            </MotionWrapper>
        </div>
    );
};

export default Save_Date;
