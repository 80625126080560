import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';

const KoEunYoung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "채성석 🖤 고은영 결혼합니다.";
    const openDesc = "25년 2월 22일 오후 13시 50분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/23/thumb.jpg";
    
    const placeTelNum = "063-467-7000";

    const forCar = "- 서해안 고속도로 이용시\n동군산 I.C → 전주 군산간 자동차 전용도로 \n→ 군산대/군산공항 I.C → 군산대학교 방향 직진 \n → 은파유원지 → 은파팰리스웨딩&컨벤션\n\n- 호남 고속도로 이용시\n전주 I.C → 전주 군산간 자동차 전용도로 \n → 군산대/군산공항 I.C → 군산대학교 방향 직진 \n→ 은파유원지 → 은파팰리스웨딩&컨벤션"
    const forBus = "- 군산 시외버스터미널에서 출발: \n은파 호수공원 방향 41~44번 버스 탑승 \n→ 은파팰리스 인근 정류장 하차 (도보 약 5분 소요).\n\n- 군산역에서 출발:\n9번 또는 12번 버스 탑승 \n → 은파 호수공원 정류장 하차 (도보 약 10분 소요)."
    const forParking = "웨딩홀 뒷편 주차타워3층 보유, 건물 주차장 이용가능\n은파유원지 공영주차장 무료이용";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "채성석",
            bank: "하나은행",
            accountNumber : "200-910256-86607",
            isKakao :true,
            kakaoUrl : "https://qr.kakaopay.com/FJKNCuHuX"
        },
        {
            role: "신랑 부",
            name: "채규삼",
            bank: "기업은행",
            accountNumber : "183-055313-01-015",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "고은영",
            bank: "카카오뱅크",
            accountNumber : "3333-06-9614157",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej9BW06M6"
        },
        {
            role: "신부 부",
            name: "고의곤",
            bank: "농협은행",
            accountNumber : "527011-56-000773",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "최미옥",
            bank: "신한은행",
            accountNumber : "110-192-299989",
            isKakao : false
        }
    ]



    const msg1 = "작은 우연들이 한데 모여 인연이 되고\n그 인연이 서로의 마음에 수놓아\n운명처럼 사랑하게 되었습니다";
    const msg2 = "부부의 이름으로 하나 되어 보내는 첫 번째 편지\n저희가 함께 가는 먼 길의 시작점에 \n소중한 분들을 초대합니다.";

    const lng = 126.689739766219;
    const lat = 35.9541603009515;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall weddingData={weddingData} />
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"4px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forParking={forParking} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KoEunYoung;