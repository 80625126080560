import React, { useState, useEffect } from "react";
import axios from "axios";
import moreBtn from "../../css/moreBtn.png";
import foldBtn from "../../css/foldBtn.png";
import xImg from "../../css/x.png";
import "../../css/gallery_cards.css";
import nextImg from '../../asset/next.png';
import preImg from '../../asset/pre.png';

const GalleryItem2 = ({ weddingData, initCount, photoWidth, photoHeight, gap, speed }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [galleryList, setGalleryList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(initCount);
  const [initialVisibleCount, setInitialVisibleCount] = useState(initCount);

  useEffect(() => {
    const preventPinchZoom = (e) => {
      e.preventDefault();
    };
    document.addEventListener("gesturestart", preventPinchZoom);
  }, []);

  const fetchGallery = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/_api/v1/invitation_info/gallery/${weddingData.id}`
      );
      if (response.status === 200) {
        setGalleryList(response.data.data);
      }
    } catch (error) {
      console.error("API 요청 실패:", error.response ? error.response.data : error.message);
    }
  };

  const openSlide = (idx) => {
    setIndex(idx);
    setOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeSlide = () => {
    requestAnimationFrame(() => {
      setOpen(false);
      document.body.style.overflow = "auto";
    });
  };

  useEffect(() => {
    if (weddingData && galleryList.length === 0) {
      fetchGallery();
    }
  }, [weddingData]);

  const loadMoreImages = () => {
    setVisibleCount((prevCount) => prevCount + initCount);
  };

  const resetImages = () => {
    setVisibleCount(initCount);
    setInitialVisibleCount(initCount);
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + galleryList.length) % galleryList.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % galleryList.length);
  };

  return (
    <div className="gallery_card_container" style={{ gap: gap }}>
      {galleryList.slice(0, visibleCount).map((image, idx) => (
        <img
          key={idx}
          className="card_photo"
          style={{ width: photoWidth, height: photoHeight, maxWidth: "329px", cursor: "pointer" }}
          src={image}
          alt={`photo${idx + 1}`}
          onClick={() => openSlide(idx)}
        />
      ))}

      <div style={{ textAlign: "center", marginTop: "20px", width: "300px", height: "50px" }}>
        {visibleCount < galleryList.length && (
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={loadMoreImages}
          >
            <img style={{ width: "15px", height: "10px", cursor: "pointer" }} src={moreBtn} alt="More" />
          </span>
        )}

        {visibleCount !== initialVisibleCount && visibleCount >= galleryList.length && (
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={resetImages}
          >
            <img style={{ width: "15px", height: "10px", cursor: "pointer" }} src={foldBtn} alt="Fold" />
          </span>
        )}
      </div>

      {open && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, .9)",
            textAlign: "center",
            zIndex: "2000",
          }}
        >
          <img
            className="x_img"
            src={xImg}
            alt="Close"
            onClick={closeSlide}
            style={{
              position: "absolute",
              top: "4%",
              right: "10px",
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
          />

          <div
            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
          >
            <img src={preImg} style={{ width: "20px", height: "23px", cursor: "pointer", marginRight: "10px" }} onClick={handlePrev}/>
            <img
              className={`gallery_image ${open ? (index === index ? "showing" : "hidden") : ""}`} 
              loading="lazy"
              src={galleryList[index]}
              alt={`Gallery Image ${index + 1}`}
              style={{ width: "78%", maxWidth: "360px" }}
            />
            <img src={nextImg} style={{ width: "20px", height: "23px", cursor: "pointer", marginLeft: "10px" }} onClick={handleNext} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryItem2;
