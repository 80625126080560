import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';

const KangHyeRim = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "김기강 ♥ 강혜림 결혼합니다.";
    const openDesc = "2025년 4월 6일 (일) 오후 2시 20분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/70/thumb.jpg";

    const lng = 126.890227293528;
    const lat = 37.5070438414784;

    const placeTelNum = "02-2111-7000";


    const forCar = "신도림 테크노마트 지하 주차장 (B3-B7) \n2,500대 3시간 무료\n\n※ 테크노마트 내 에스컬레이터를 이용하시면 더욱 빠르고 편리합니다.";
    const forBus = "- 신도림역 ②번 출구 하차\n지선 : 5619, 6411, 6511, 6650, 6651\n마을 : 영등포01, 영등포09, 영등포12, 영등포13\n\n- 신도림역 ①번 출구 하차 \n(지하보도를 이용하여 ②번 출구로 이동)\n간선 : 160, 503, 600, 662, 670\n지선 : 5615, 5714, 6612, 6513, 6516, 6637, 6640\n광역 : 301, 320, 510\n경기 : 10, 11-1, 11-2, 83, 88";
    const forSubway = "1호선, 2호선 신도림역 하차 ②번 출구\n테크노마트 판매동 지하1층과 직접 연결되어있습니다.";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#1E5EA3",
        pointColor: "#1E5EA3",
        btnBgColor: "#5993D8",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const groomAccountList = [
        {
            role: "신랑",
            name: "김기강",
            bank: "신한은행",
            accountNumber : "110-352-893-878",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FYsjeV64q"
        },
        {
            role: "신랑 부",
            name: "김주환",
            bank: "국민은행",
            accountNumber : "447901-04-021959",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "권명숙",
            bank: "기업은행",
            accountNumber : "411-020738-01-010",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "강혜림",
            bank: "우리은행",
            accountNumber : "1002-161-779578",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej72leqH0"
        },
        {
            role: "신부 모",
            name: "신현정",
            bank: "카카오뱅크",
            accountNumber : "3333-04-2859143",
            isKakao : false
        }
    ]

    const msg1 = "함께한 지 7년이 되는 봄날,\n인생이라는 여행을 함께 떠나보려 합니다.";
    const msg2 = "부부라는 이름으로 하나 되는 첫 출발을\n부디 귀한 시간 내주시어 함께 축복해 주시면\n더없는 기쁨으로 간직하겠습니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기재합니다.\n부담 안 가지셨으면 좋겠습니다.";

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "첫눈에 반함",
            brideAnswer: "줘썬텐미닛"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "가즈아",
            brideAnswer: "드디어ㅠㅠ"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "암욜맨",
            brideAnswer: "암욜걸"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "오빠 믿지?",
            brideAnswer: "여생 올인ㅎ"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "기강&혜림",
            brideAnswer: "잘 살게요♥"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#1E5EA3"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer isNotWelcome={true} weddingData={weddingData} color={"#ffffff"} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} top={"84%"} bottom={"28%"} bottom2={"20%"} dateBottom={"3%"} />
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"드디어 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KangHyeRim;