import React from 'react';
import AccountCard from './AccountCard';
import line from '../../css/line.png';

const AccountList2 = ({conceptStyle, groomAccountList, brideAccountList, account_explain, titleFontSize, fontSize, isBold}) => {
    return (
        <div className='basic_column_container' style={{fontFamily: conceptStyle.fontFamilyKo}}>
            <h3 className='spring_h3_title' style={{color: conceptStyle.titleColor, fontFamily: conceptStyle.fontFamilyEn}}>ACCOUNT NUMBER</h3>
            <p className='p1_score_14' style={{fontFamily: conceptStyle.fontFamilyKo, paddingBottom: "20px", fontSize: titleFontSize ? titleFontSize : "14px", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor : "#313131", fontWeight: isBold ? 600 : 300}}>마음 전하실 곳</p>
            <p className='p1_score_12' style={{fontFamily: conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor : "#313131", fontWeight: isBold ? 600 : 300}}>{account_explain}</p>

            {/* 신랑측 계좌 목록 */}
            <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                <p style={{ fontSize: fontSize ? fontSize : "13px", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor : "#313131", fontWeight: isBold ? 600 : 300 }}>신랑측</p>
                {groomAccountList.map((groomAccount, index) => (
                    <AccountCard
                        key={index}
                        role={groomAccount.role}
                        name={groomAccount.name}
                        bank={groomAccount.bank}
                        accountNumber={groomAccount.accountNumber}
                        isKakao={groomAccount.isKakao}
                        kakaoUrl={groomAccount.isKakao ? groomAccount.kakaoUrl : undefined}
                        titleFontSize={titleFontSize}
                        fontSize={fontSize}
                        isBold={isBold ? isBold : false}
                        baseFontColor={conceptStyle.baseFontColor ? conceptStyle.baseFontColor : "#313131"}
                    />
                ))}
            </div>

            {/* 신부측 계좌 목록 */}
            <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                <p style={{ fontSize: fontSize ? fontSize : "13px", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor : "#313131", fontWeight: isBold ? 600 : 300 }}>신부측</p>
                {brideAccountList.map((brideAccount, index) => (
                    <AccountCard
                        key={index}
                        role={brideAccount.role}
                        name={brideAccount.name}
                        bank={brideAccount.bank}
                        accountNumber={brideAccount.accountNumber}
                        isKakao={brideAccount.isKakao}
                        kakaoUrl={brideAccount.isKakao ? brideAccount.kakaoUrl : undefined}
                        titleFontSize={titleFontSize}
                        fontSize={fontSize}
                        isBold={isBold ? isBold : false}
                        baseFontColor={conceptStyle.baseFontColor ? conceptStyle.baseFontColor : "#313131"}
                    />
                ))}
            </div>

            <img className='line_img' src={line} alt='Line Image'></img>
        </div>
    );
};

export default AccountList2;
