import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import babyStepMusicFile from "../asset/music/babyStep.mp3";
import GalleryList from '../component/Gallery/GalleryList';

const KimTaeYoung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "지승❤️태영 결혼합니다!";
    const openDesc = "4월 12일 오전 11시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/34/thumb.jpeg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김지승",
            bank: "카카오뱅크",
            accountNumber : "3333-09-1656315",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8BVNAdI"
        },
        {
            role: "신랑 부",
            name: "김세진",
            bank: "농협은행",
            accountNumber : "556-12-185862",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "임명화",
            bank: "농협은행",
            accountNumber : "154-02-254447",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김태영",
            bank: "국민은행",
            accountNumber : "92748920845",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7mhjW9a"
        },
        {
            role: "신부 부",
            name: "김재석",
            bank: "기업은행",
            accountNumber : "010-7272-9208",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "박명숙",
            bank: "신한은행",
            accountNumber : "110-085-692004",
            isKakao : false
        }
    ]

    const msg1 = "예쁜 예감이 들었다.\n우리는 언제나 \n손을 잡고 있게 될 것이다.";
    const msg2 = "- 연인, 이이체 -";
    const msg3 = "봄 햇살이 함께했던 지난 날,\n여러 해의 봄날 처럼\n앞으로도 햇살같은 봄날을 함께하려 합니다."
    const msg4 = "4월의 어느 봄날\n늘 곁에서 아껴주셨던 고마운 분들을 모십니다."

    const lng = 126.904586710033;
    const lat = 37.5213571324809;

    const placeTelNum = "02 6418 3000";

    const forParking = "- 건물 주차장 이용 가능\n- 영남 주차장(상황에 따라 운영합니다.)\n\n주말에는 차량이 많아 여유롭게 오시길 권장드립니다.";
    const forBus = "[대림호수공원아파트 하차] 77, 98, 99-1\n[동남레이크빌 하차] 77, 98, 99-1, 314, 3100, 3101"
    const forSubway = "- 1호선 영등포역 하차\n영등포 뉴타운 지하상가 4번 출구 (새길병원방면)\n- 5호선 영등포시장역 하차 4번출구"
    const forCar = "- 웨딩그룹위더스 영등포 검색\n- 서울 영등포구 영중로 55 또는 \n서울 영등포구 영등포동 6가7"

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={babyStepMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"지승❤️태영 결혼합니다!"} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={4} photoWidth={"auto"} hotoHeight={"235px"} gap={"4px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default KimTaeYoung;
