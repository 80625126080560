import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import CallInfo from '../component/CallInfo';

const KimMinJi2 = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "윤만선 ♥ 김민지 결혼합니다.";
    const openDesc = "2025년 3월 1일 오후 12시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/57/thumb.JPG";
    
    const placeTelNum = "0507-1445-7847";

    const forBus = "10-2, 10-7 안성경찰서 정거장 하차(도보 1분)";
    const forShuttleBus = "1.안산 중앙역 4호선 1번출구 앞 오전 10:00 출발\n2.시흥 정왕동 미관광장 앞 오전 10:00 출발";
    const forCar = "경기도 안성시 미양로 786-2 더에이더블유 웨딩컨벤션 \n주차장";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const groomAccountList = [
        {
            role: "신랑",
            name: "윤만선",
            bank: "농협은행",
            accountNumber : "352-0110-5485-53",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "윤세공",
            bank: "농협은행",
            accountNumber : "237101-52-043099",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "최미아",
            bank: "농협은행",
            accountNumber : "356-0077-6953-63",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김민지",
            bank: "새마을금고",
            accountNumber : "9003-2466-3958-5",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "김동수",
            bank: "새마을금고",
            accountNumber : "9002-1365-3048-9",
            isKakao : false
        }
    ]

    
    const msg1 = "2017년 어느 여름날 연인이 되어\n일곱번의 사계절을 함께 보냈습니다.\n평생 좋은 친구로, 사랑하는 연인으로\n서로 아껴주고 사랑하며 살겠습니다.";
    const msg2 = "앞으로 함께 나아갈 수많은 계절,\n그 시작의 자리에 \n소중한 걸음으로 축복해 주세요.";

    const lng = 127.249748471701;
    const lat = 36.99693791145;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김민지",
            call: "010-3268-9129"
        },
        {
            role: "신부 부",
            name: "김동수",
            call: "010-8831-9129"
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "윤만선",
            call: "010-7438-7417"
        },
        {
            role: "신랑 부",
            name: "윤세공",
            call: "010-2563-7417"
        },
        {
            role: "신랑 모",
            name: "최미아",
            call: "010-8972-7417"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        // document.body.style.overflow = 'hidden';
    
        // const timer = setTimeout(() => {
        //     document.body.style.overflow = '';
        // }, 3000);
    
        // return () => {
        //     clearTimeout(timer);
        //     document.body.style.overflow = '';
        // };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#000000"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#000000"} dateColor={"#000000"} top={"1%"} bottom={"25%"} bottom2={"17%"} dateBottom={"4%"} />
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forShuttleBus={forShuttleBus} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KimMinJi2;