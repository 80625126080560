import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import winterMusicFile from "../asset/music/winter.mp3";
import Main_Summer from '../component/Summer/Main_Summer';
import Information from '../component/Information';

const KimSeungHee = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "조여진 ♥ 김승희 결혼합니다.";
    const openDesc = "2025. 03. 22 SAT 11:30";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/47/thumb2.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "나의 사랑하는 자가\n내게 말하여 이르기를\n나의 사랑\n내 어여쁜자야\n일어나서 함께 가자\n아가서 2:10";
    const msg2 = "서로를 허락하신 하나님 앞에서\n사랑으로 만난 두 사람이\n서로의 손을 잡고 함께 평생을 걸어가고자 합니다.\n저희 앞날을 소중한 발걸음으로 \n축복해주시길 소망합니다.";

    const lng = 128.902639762819;
    const lat = 35.2277574984946;
    
    const placeTelNum = "055)331-4884";

    const forCar = "'경남 김해시 김해대로 2520 (김해JW웨딩)' 입력";
    const forParking = "웨딩홀 지상 주차장 이용 / 만차시 인근 메가마트 주차장 이용 가능";
    const forSubway = "- 사상 : 김해방향 경전철 인제대역 하차 1번출구\n- 구포 : 지하철 3호선 탑승 후 대저역에서 \n김해방향 경전철 환승 인제대역 하차 1번출구"
    const forBus = "- 부산 : 123, 127, 1004번 인제대역 하차 맞은편 \n도보 5분 거리\n- 김해 : 2, 2-1, 7 인제대역 하차";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "조여진",
            bank: "하나은행",
            accountNumber : "678-910305-55507",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej89azF0Z"
        },
        {
            role: "신랑 모",
            name: "최태순",
            bank: "농협은행",
            accountNumber : "352-1382-2943-23",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김승희",
            bank: "국민은행",
            accountNumber : "275601-04-463325 ",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7lf6Nxa"
        },
        {
            role: "신부 부",
            name: "김두영",
            bank: "농협은행",
            accountNumber : "841067-52-022965",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "김미란",
            bank: "농협은행",
            accountNumber : "891-02-206664",
            isKakao : false
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "첫눈에 반함",
            brideAnswer: "기억이 생생"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "나 장가 가요",
            brideAnswer: "설레는 시작"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "내가 더 아껴",
            brideAnswer: "많이 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "가화만사성",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 하객분들께 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "감사랑해요"
        }
    ]

    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 1일 11:30 \n거창축협 한우팰리스"
        }
    
    ]

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join('').toLowerCase(); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName; // 결과 문자열 반환
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={winterMusicFile}/>
            <Main_Summer weddingData={weddingData} color={"#ff8faf"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} top={"1%"} bottom={"25%"} bottom2={"17%"} dateBottom={"5%"} />
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"WEDDING WORSHIP"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} gap={"3px"} photoWidth={"153px"} hotoHeight={"235px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forBus={forBus} forParking={forParking} forSubway={forSubway} forCar={forCar} conceptStyle={conceptStyle}/>
                                <Information conceptStyle={conceptStyle} informationList={informationList} />
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default KimSeungHee;
