import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Information from '../component/Information';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';

const HwangHwaYeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김태우 ❤️ 황화연 결혼합니다";
    const openDesc = "3월 22일 13시20분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/63/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });
    
    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김태우",
            bank: "신한은행",
            accountNumber : "110-353-086210",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7794Q2h"
        },
        {
            role: "신랑 아버지",
            name: "김수철",
            bank: "우리은행",
            accountNumber : "1002-629-959755",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "황화연",
            bank: "신한은행",
            accountNumber : "110-351-792820",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8tP2XIo"
        },
        {
            role: "신부 아버지",
            name: "황용현",
            bank: "기업은행",
            accountNumber : "022-053925-01-011",
            isKakao : false
        },
        {
            role: "신부 어머니",
            name: "이옥점",
            bank: "국민은행",
            accountNumber : "036-21-0933-881",
            isKakao : false
        }
    ]

    // const groomCallInfoList = [
    //     {
    //         role: "신랑",
    //         name: "윤호창",
    //         call: "010-5069-8939"
    //     },
    //     {
    //         role: "신랑 부",
    //         name: "윤양석",
    //         call: "010-5533-8292"
    //     },
    //     {
    //         role: "신랑 모",
    //         name: "김옥희",
    //         call: "010-2589-8939"
    //     }
    // ]
    // const brideCallInfoList = [
    //     {
    //         role: "신부",
    //         name: "김송",
    //         call: "010-5504-0922"
    //     },
    //     {
    //         role: "신부 모",
    //         name: "000",
    //         call: "010-5059-1889"
    //     }
    // ]

    const msg1 = "하루의 시작과 끝, \n한 해의 첫날과 마지막 날을 ";
    const msg2 = "함께 하고 싶은 사람을 만나 \n이제는 평생을 약속하고자 합니다.\n저희의 사랑이 결실을 맺는 뜻깊은날 함께해주시면 \n더 없는 기쁨으로 간직하겠습니다.";

    const lng = 126.890227293528;
    const lat = 37.5070438414784;
    
    const placeTelNum = "02-2111-8888";

    const forCar = "네비게이션 '신도림 테크노마트'입력후 지하주차장 이용";
    const forBus = "신도림역(17-102) 정류장 하차\n-지하철 신도림역 3번출구쪽 \n지선: 6519, 6411, 6511, 6611\n직행: 5200\n마을: 영등포09, 영등포12, 영등포13\n신도림역(17-001) 정류장 하차\n\n-지하철 신도림역 1번출구쪽 \n(하차후 지하보도 이용, 지하철 3번출구 방면으로 이동 \n -> 테크노마트 판매동 지하1층 통로를 이용)\n* 간선: 160, 503, 600, 660, 662\n* 지선: 5615, 5714, 6512, 6515, 6516, 6637, 6640A, \n6713\n* 직행: 301, 320\n공항: 6018";
    const forSubway = "1호선 2호선 신도림역 하차 3번출구 \n테크노마트 판매동 지하1층과 연결되어있습니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다.";


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "어떻게잊어",
            brideAnswer: "절대못잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "걱정설렘반",
            brideAnswer: "신나는기분"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "선을지키자",
            brideAnswer: "나만믿어봐"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "1+1이야",
            brideAnswer: "살찔각오해"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "평생함께해",
            brideAnswer: "잘해봅시다"
        }
    ]

    // const informationList =[
    //     {
    //         title : "화환 안내",
    //         content : "화환은 정중히 사양합니다. \n축하해주시는 따듯한 마음만 \n감사히 받겠습니다. "
    //     }
    // ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼 합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
            <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={5} photoWidth={"auto"} hotoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default HwangHwaYeon;
