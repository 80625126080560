import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';

const ShinYouJung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "고귀한 ♥ 신유정 결혼합니다";
    const openDesc = "2025.04.12 (토) 오후 3시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/66/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "고귀한",
            bank: "기업은행",
            accountNumber : "111-148367-01-010",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej9ErwuLD"
        },
        {
            role: "신랑 부",
            name: "고충선",
            bank: "농협은행",
            accountNumber : "207816-51-015671",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "신유정",
            bank: "신한은행",
            accountNumber : "110-166-506305",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FDoCeWLUr"
        },
        {
            role: "신부 부",
            name: "신상현",
            bank: "신한은행",
            accountNumber : "110-004-341552",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "김은희",
            bank: "신한은행",
            accountNumber : "368-06-685399",
            isKakao : false
        }
    ]
    
    const msg1 = "벚꽃이 흩날리던 어느 봄 처음 만난 저희 두 사람\n또 다시 벚꽃 피는 아름다운 봄날 \n평생을 약속하려 합니다";
    const msg2 = "저희의 사랑이 결실을 맺는 뜻깊은 날 \n@귀한@ 걸음 해주시어 함께해 주시면 \n더 없는 기쁨으로 간직하겠습니다 ";

    const lng = 127.075022075792;
    const lat =  37.254484134021;
    
    const placeTelNum = "031-206-6660";

    const forBus = "영통역 정류장 하차 [04150]\n• 일반버스 2-1, 3, 5, 13-1, 34, 34-1, 203, 720-3\n• 광역버스 M5107\n• 직행버스 1112, 5100, 7000, G5100";
    const forSubway = "수인분당선 영통역 1번 출구\n1번 출구 직진방향 홈플러스 방면 도보 4분거리";
    const forCar = "네비게이션 : 'SY컨벤션웨딩홀' 또는 '보보스프라자' 입력\n주소 검색 : '수원 봉영로 1612' 또는 '수원 영통동 996-4' 검색 ";


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    // const interviewContent = [
    //     {
    //         question : "결혼을 앞둔 소감이 어때?",
    //         groomAnswer: "잊지 못할 날",
    //         brideAnswer: "한번뿐인 날"
    //     },
    //     {
    //         question : "서로에게 반한 이유는?",
    //         groomAnswer: "운명 같아서",
    //         brideAnswer: "딱! 곰돌이 푸"
    //     },
    //     {
    //         question : "서로에게 하고 싶은 말은?",
    //         groomAnswer: "늘 함께하자",
    //         brideAnswer: "늘 사랑하자"
    //     },
    //     {
    //         question : "유부남/녀 기념 각오 한마디!",
    //         groomAnswer: "말을 잘 듣자!",
    //         brideAnswer: "그만 혼내자!"
    //     },
    //     {
    //         question : "마지막으로 전하고 싶은 이야기는?",
    //         groomAnswer: "축하해줘요",
    //         brideAnswer: "많이많이(하트)"
    //     }
    // ]
    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"초대합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default ShinYouJung;
