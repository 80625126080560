import React from 'react';

const Video = ({ url , conceptStyle, width, height, isNotTitle}) => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: "60px 0" }}>
            { !isNotTitle &&
                <h3 className='spring_h3_title' style={{paddingTop: "30px", paddingBottom: "20px", color: conceptStyle.titleColor, fontFamily: conceptStyle.fontFamilyEn}}>VIDEO</h3>
            }

            <div style={{ width: width?width:'100%', maxWidth: '800px', height: height?height:"auto",aspectRatio: '16 / 9', position: 'relative' }}>
                <iframe
                    src={`${url}?autoplay=1&loop=1`} 
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Video"
                ></iframe>
            </div>
        </div>
    );
};

export default Video;
