import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const KimBoHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "태규♥보현 결혼합니다.";
    const openDesc = "2025년 5월 11일 오후 12시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/77/thumb.jpg";

    const lng = 127.04657799848736;
    const lat = 37.50328288108072;

    const placeTelNum = "02-2183-0230";

    const forRentBus = "7시30분 성서홈플러스앞\n연락처 최경일 010-6427-7665";
    const forBus = "1. 한국자산신탁, 르네상스호텔, 서울상록회관 정류장\n 선릉역 방향 이동 두꺼비 빌딩 지나 30M 직진, \n한신인터밸리24빌딩 4층\n\n2. 선릉역 1번,10번 출구 정류장 기준\n선릉역 1번,10번 출구 앞 정류장 하차 후 역삼역 방향으로 \n이동, 선릉역 4번 출구에서 100M 직진 시 좌측 \n한신인터밸리24빌딩 4층";
    const forSubway = "선릉역(2호선, 분당선) 4번 출구로 나와, \nIBK기업은행을 지나 50M 직진, \n맥도날드 옆 한신인터밸리24빌딩 4층";
    const forParking = "'한신인터밸리24 주차장'을 검색 시 \n건물 뒷편 주차장 입구로 안내됩니다.";
    
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#FA7384",
        pointColor: "#FA7384",
        btnBgColor: "#FA7384",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const msg1 = "같이 있으면 매일이 즐거워\n평생을 행복하게 만들어주고 싶습니다.";
    const msg2 = "기쁠 때나 슬플 때나 서로가 함께라면\n항상 그 끝은 행복일 것이라는 확신과 함께\n앞으로 평생을 함께 하고자 합니다.";
    const msg3 = "이러한 선물 같은 날\n함께 자리를 빛내 주시면 감사드리겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "최태규",
            bank: "국민은행",
            accountNumber : "020602-04-223445",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej9C6n8f5"
        },
        {
            role: "신랑 부",
            name: "최경일",
            bank: "농협은행",
            accountNumber : "714-12-335617",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김진우",
            bank: "농협은행",
            accountNumber : "150073-52-158527",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김보현",
            bank: "신한은행",
            accountNumber : "110-234-457405",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7i5Zm0u"
        },
        {
            role: "신부 부",
            name: "김충진",
            bank: "신한은행",
            accountNumber : "110-140-232974",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "최민재",
            bank: "신한은행",
            accountNumber : "110-003-662910",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "찾았다요놈",
            brideAnswer: "잡았다요놈"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "우리잘살자",
            brideAnswer: "너무설렌다"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생내꾸해",
            brideAnswer: "평생행복해"
        },
    ]

const groomCallInfoList = [
        {
            role: "신랑",
            name: "최태규",
            call: "010 8834 4976"
        }
        // ,
        // {
        //     role: "신랑 부",
        //     name: "강기수",
        //     call: "010 3747 9533"
        // },
        // {
        //     role: "신랑 모",
        //     name: "김미숙",
        //     call: "010 9067 1678"
        // }
    ]
    const brideCallInfoList = [
        {
            role: "신부",
            name: "김보현",
            call: "010 2186 7191"
        }
        // ,
        // {
        //     role: "신부 부",
        //     name: "김현석",
        //     call: "010 6386 7792"
        // },
        // {
        //     role: "신부 모",
        //     name: "박채희",
        //     call: "010 5899 3546"
        // }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
        // 7FAD2A 그린
        // FA7384 핑크
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FA7384"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#000000"} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#FA7384"} dateColor={"#000000"} bottom={"24%"} bottom2={"16%"} dateBottom={"84%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"우리 결혼 합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forRentBus={forRentBus} forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KimBoHyeon;