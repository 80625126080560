import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Information from '../component/Information';
import CallInfo from '../component/CallInfo';
import Info_Spring2 from '../component/Spring/Info_Spring2';
import Video from '../component/Video';

const KimSong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "윤호창💛김송 \n 두 사람이 부부가 됩니다.";
    const openDesc = "2025년 5월 17일 11시 \n진주 호텔동방 3층 그랜드볼룸홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/62/thumb2.png";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // 빈티지 연핑크
        concept: "SPRING",
        titleColor: "#edacb1",
        pointColor: "#edacb1",
        btnBgColor: "#edacb1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "윤호창",
            bank: "농협은행",
            accountNumber : "302-0640-7590-71",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9IT9SHJ"
        },
        {
            role: "신랑 아버지",
            name: "윤양석",
            bank: "농협은행",
            accountNumber : "356-1262-2450-53",
            isKakao : false
        },
        {
            role: "신랑 어머니",
            name: "김옥희",
            bank: "농협은행",
            accountNumber : "352-1395-3008-83",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김송",
            bank: "국민은행",
            accountNumber : "652702-04-339837",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9FAPL0C"
        },
        {
            role: "신부 어머니",
            name: "유상조",
            bank: "농협",
            accountNumber : "835091-52-030314",
            isKakao : false
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "윤호창",
            call: "010-5069-8939"
        },
        {
            role: "신랑 부",
            name: "윤양석",
            call: "010-5533-8292"
        },
        {
            role: "신랑 모",
            name: "김옥희",
            call: "010-2589-8939"
        }
    ]
    const brideCallInfoList = [
        {
            role: "신부",
            name: "김송",
            call: "010-5504-0922"
        },
        {
            role: "신부 모",
            name: "유상조",
            call: "010-5059-1889"
        }
    ]


    const msg1 = "저희가 처음 만났던 따뜻한 봄날\n사랑이라는 이름으로\n부부의 연을 맺습니다.";
    const msg2 = "몇 번의 해가 지나고\n몇십 번의 사계절이 흘러도\n사랑을 잊지 않고, 우리를 잃지 않고\n처음 만났을 때의 반짝거림을 기억하며\n꿈꾸듯이 살겠습니다.";
    const msg3 = "새로운 시작에 소중한 분들을 모십니다.\n귀한 걸음 하시어 저희의 시작을 축복해 주세요.";

    const lng = 128.092536845816;
    const lat = 35.1903618185496;
    
    const placeTelNum = "055-760-1600";

    const forCar = "진주 IC 진입 후, 진주교 통과 즉시 우회전하여 강변 따라 7분간 직진 (5.4km)";
    const forBus = "- 시외버스 터미널 : 도보 5분 이내 (450m)\n- 고속버스 터미널 : 택시 이용 시 5분 소요(2km)";
    const forParking = "- 호텔 뒤 주차타워 이용 \n- 만차 시 주차타워 뒤쪽 지상 주차장 이용";
    const forTrain2 = "진주역 : 택시 이용 시 20분 소요(7km)";

    const account_explain = "멀리서도 축하의 마음을 전하고 싶으신 분들을 위해 \n계좌번호를 안내드립니다.\n\n소중한 축하를 보내주셔서 감사드리며, \n따듯한 마음에 깊이 감사드립니다."

    const parents = [
        {groom : "윤양석 · 김옥희의 아들"},
        {bride : "故김경영 · 유상조의 딸"}
    ]

    // const informationList =[
    //     {
    //         title : "화환 안내",
    //         content : "화환은 정중히 사양합니다. \n축하해주시는 따듯한 마음만 \n감사히 받겠습니다. "
    //     }
    // ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#edacb1"} loading={loading} />
            ) : (
            <>
            {/* <Music concept={conceptStyle.concept} /> */}
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"윤호창 그리고 김송"} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
            <Video isNotTitle={true} width={"100%"} url={"https://moodsean.s3.ap-northeast-2.amazonaws.com/62/video_song.mp4"} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} photoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forParking={forParking} forTrain2={forTrain2} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default KimSong;
