import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';


const ShinSoBin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "주원이랑 소빈이 결혼합니다♥";
    const openDesc = "25년 3월 8일 토요일 낮 12:10";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/10/thumb_sobin.jpg";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김주원",
            bank: "카카오뱅크",
            accountNumber : "3333-10-2987707",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FVWkXGZ6s"
        },
        {
            role: "신랑 아버지",
            name: "김금봉",
            bank: "아이엠뱅크",
            accountNumber : "140-08-0319-558",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "신소빈",
            bank: "카카오뱅크",
            accountNumber : "3333-22-3814386",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7wNEzCU"
        },
        {
            role: "신부 아버지",
            name: "신문선",
            bank: "농협은행",
            accountNumber : "356-0704-8688-63",
            isKakao : false
        }
    ]
    
    const msg1 = "꽃향기 가득한 봄날\n인생의 소중한 짝꿍을 만나\n새로운 인생을 시작하려 합니다.";
    const msg2 = "봄처럼 따뜻한 마음으로\n저희의 새로운 시작을 축복해 주세요.";

    const lng = 126.995309613603;
    const lat = 37.2715048903505;

    const placeTelNum = "031-297-1000";

    const forCar = "'파티웨딩유' 입력 \n(*본 건물 전용 주차 공간 약 700대 무료 이용 가능)";
    const forBus = "수원역 정류장 하차 (모든 버스 수원역 하차)\n\n- 파랑[간선버스]: 110, 1004, 4300-1\n- 빨강[광역버스]: 110, 707, 701-1, 909, 1007, \n2007, 3000, 7770, 8851, 8401, 8409\n- 초록[지선버스]: 2-1, 2-2, 5, 5-2, 7-1, 7-2, 7-1A, 9, \n 9-1, 10, 10-2, 10-5, 11, 11-1, 13, 13-1, 13-4, \n 13-5, 15-1, 16-2, 32-3, 32-4, 35, 37, 50-2, 60, \n 66, 66-4, 82-1, 83-1, 88, 88-1, 92, 92-1, 123A, \n 123D, 150, 151, 301, 310, 400, 400-4, 720-2, \n777, 900, 999, 마을6, H120A"
    const forSubway = "- 1호선 / 분당선 수원역 하차\n - 셔틀 버스: 수원역 지하상가 13번 출구 \n 공항 리무진버스 정류장 (*수시운행)\n- 수원역 4번 출구 (교통안전공단 방향) 800m 이내"
    
    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너 귀여웠어",
            brideAnswer: "너무 웃겼어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "몽글몽글~",
            brideAnswer: "너무 떨려요"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "웃게 해줄게",
            brideAnswer: "예뻐해 줄게"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "알콩달콩♥",
            brideAnswer: "나랑만 놀자"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축복해 줘요",
            brideAnswer: "많이 많이요"
        }
    ]

    const parents = [
        {groom : "김금봉 · 이성순의 장남"},
        {bride : "신문선 · 차석미의 차녀"}
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor} fontWeight={600}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"결혼합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring weddingData={weddingData} conceptStyle={conceptStyle} interviewContent={interviewContent}/>
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default ShinSoBin;
